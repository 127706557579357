
import React from 'react'
import SectionButton from "../common/SectionButton";
import BackButton from "../common/BackButton";


class AutofillMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {

        return(<div className="main-wrap">
            <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>

            <div className="autofill">

                <div className="header">
                    <h1 className="head-title office">Personal / Business Details</h1>
                </div>
                <div>The details within these sections will automatically populate where possible, in your Gas App documents.</div>


                <div className="cell small-6 ">
                    <SectionButton title="Company Logo Upload"
                                   sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.AUTOFILL_LOGO)}
                                   iconClass=""/>
                    <SectionButton title="Invoice &amp; Quote Details"
                                   sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.AUTOFILL_INVOICE_QUOTE)}
                                   iconClass=""/>
                    <SectionButton title="Late Payment Terms"
                                   sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.AUTOFILL_LATE_TERMS)}
                                   iconClass=""/>

                </div>
                <div className="cell small-6 ">
                    <SectionButton title="Your Details &amp; Business Details"
                                   sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.AUTOFILL_DETAILS_YOURS_BUSINESS)}
                                   iconClass=""/>

                    <SectionButton title="Payment Details"
                                   sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.AUTOFILL_DETAILS_PAYMENT)}
                                   iconClass=""/>
                </div>
            </div>
        </div>);

    }


}

export default AutofillMenu;
