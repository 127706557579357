import React from 'react'
import BackButton from "../common/BackButton"
import { API } from '../../api/APIClient'
import Axios from 'axios'

class ProfilePicture extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password: "",
            newPassword: "",
            newPassword2: "",
            submitting:false,
            submitMessage:"",
            image: null}
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
    }



    fileChangedHandler = (event) => {
        const file = event.target.files[0]

        if (file.name.length > 4)
        {
            var last3 = file.name.substr(file.name.length - 3).toUpperCase();
            var last4 = file.name.substr(file.name.length - 4).toUpperCase();

            if (last4 === "JPEG" || last3 === "JPG" || last3 === "PNG")
            {
                const name = event.target.name;

                this.setState({
                    [name]: file
                });
            } else {
                alert('Please choose an image file (jpg or png)');
            }

        } else {
            alert('Please choose an image file (jpg or png)');
        }
    }

    submitChangeRequest = () => {

        if (this.state.image != null)
        {
            this.setState({
                submitting:true,
                submitMessage:"Uploading image..."
            });

            const formData = new FormData();
            formData.append('id', this.props.user.id);
            formData.append('image', this.state.image, this.state.image.name);

            Axios
                .post(API.user.uploadProfileImage, formData)
                .then((res) => {

                    this.setState({
                        submitting:false,
                        submitMessage:""
                    });
                    if (res.data.success) {

                        alert("Profile image updated successfully");
                        var updatedUsr = this.props.user;
                        updatedUsr.profilePictureUri =  res.data.data.profilePictureUri;
                        this.props.userHandler(updatedUsr)

                    } else {

                        alert("Error sending image.  Please check your connection and try again");

                    }
                })

        } else {

            alert( "Please select an image.")
        }


    }


    componentDidMount() {

    }


    render() {

        var profileImg = "";

        if (this.props.user.profilePictureUri === API.BASE_URL)
        {
            profileImg = <div className="placeholder-icon" />;

        } else {
            profileImg = <img src={this.props.user.profilePictureUri} alt="Profile Thumb" />;

        }



        return (
            <div className="main-wrap">
                <div className="cell small-12 header">
                    <BackButton backHandler={this.props.backHandler} />
                    <h1 className="head-title toolbox">My Account  > Profile Picture</h1>
                </div>
                <div className="create-box">
                    <div className="cell small-12">

                        <h2>Change your Profile Picture</h2>
                        <div className="cell small-12">
                            <p>Your profile picture is displayed along with your name when you make Break Time posts, leave comments or upload photos.</p>
                            
                        </div>
                        <div className="cell small-12 medium-6 image-thumb"><span className="create-label center-text">Your current profile picture:</span>{profileImg}</div>
                        
                        <div className="cell small-12 medium-6 prof-upload">
                        <span className="create-label center-text">Change your Profile Picture:</span>
                            <label>
                                
                                <input type="file" name="image" onChange={this.fileChangedHandler} accept=".jpg,.jpeg,.png"/>
                            </label>
                            {this.state.submitting ? <div>{this.state.submitMessage}</div> :
                            <div onClick={() => this.submitChangeRequest()} className="ga-button">Submit</div>
                            }
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default ProfilePicture;
