
import React from 'react'
import BackButton from "../common/BackButton";
import Axios from "axios";
import {API} from "../../api/APIClient";
import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";


class AutofillLateTerms extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            quoteTerms: "",
            invoiceTerms: "",
            loading: true,
            alertModalOpen : false,
            alertModalTitle : null,
            alertModalMessage: null,
            alertModalButtons : [],
        }

        this.handleChange = this.handleChange.bind(this);

    }



    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;


        this.setState({
            [name]: value
        });

    }


    componentDidMount() {

        this.getTerms();
    }


    getTerms = () => {

        Axios
            .get(API.autoFill.getLateTerms)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        quoteTerms:res.data.data.quoteTerms,
                        invoiceTerms:res.data.data.invoiceTerms,
                        loading : false
                    })

                } else {
                    this.setState({
                         loading : false
                     })
                }
            })
    }



    saveChanges() {

        const formData = new FormData();
        formData.append('invoiceTerms', this.state.invoiceTerms);
        formData.append('quoteTerms', this.state.quoteTerms);
        Axios
            .post(API.autoFill.setLateTerms, formData)
            .then((res) => {

                this.showAlertModal(
                    "Terms Saved!",
                    "Late payment terms for quotes and invoices have been saved.",
                    [
                        {
                            label : "OK ",
                            onClick : () => {
                                 this.hideAlertModal() },
                            className : "success"
                        }

                    ]
                )


            }).catch(error => {

            console.log(error.response)
            if (error.response.data.error) {

                alert(error.response.data.error)
            } else {
                alert("Could not submit terms.  Please check connection and try again.")
            }
        });


    }


    hideAlertModal() {
        this.setState({
            alertModalOpen : false
        });
    }

    showAlertModal(title, message, buttons) {
        this.setState({
            alertModalOpen : true,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        });
    }

    render() {

        if (this.state.loading){
            return(<div className="main-wrap">
                <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>

                <div className="cell small-12">
                    <div className="">Loading...</div>
                </div>
            </div>);

        } else {
            return(<div className="main-wrap">
                <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>

                <div className="autofill">


                    <div className="cell small-12">
                        <div className="header">
                            <h1 className="head-title office">Late Payment Terms</h1>
                        </div>
                    </div>
                    <div className="cell small-6">
                        <div className="cell small-12 no-pad bold-text" style={{marginBottom:"20px"}}>Late payment terms to appear under invoices:  </div>
                        <label>
                            <textarea style={{width: "100%"}} rows="9" className="msg" type="text" name="invoiceTerms" placeholder=""
                                      onChange={this.handleChange}>{this.state.invoiceTerms}</textarea>
                        </label>

                    </div>

                    <div className="cell small-6">
                        <div className="cell small-12 no-pad bold-text" style={{marginBottom:"20px"}}>Late payment terms to appear under quotes:  </div>
                        <label>
                            <textarea style={{width: "100%"}} rows="9" className="msg" type="text" name="quoteTerms" placeholder=""
                                      onChange={this.handleChange}>{this.state.quoteTerms}</textarea>
                        </label>

                    </div>

                </div>
                <div className="cell small-12  " >
                </div>
                <div className="cell small-4 no-pad" >&nbsp;
                </div>
                <div className="cell small-4 no-pad" >
                    <div className="ga-button" style={{width: "100%", marginRight:"0px"}} onClick={() => this.saveChanges()}>Save</div>
                </div>
                <div className="cell small-4 no-pad" >&nbsp;
                </div>
                <SimpleAlertDialog
                    open={this.state.alertModalOpen}
                    title={this.state.alertModalTitle}
                    message={this.state.alertModalMessage}>
                    {
                        this.state.alertModalButtons.map((button) => (
                            <ModalButton label={button.label} onClick={button.onClick}
                                         className={button.className}/>
                        ))
                    }
                </SimpleAlertDialog>
            </div>);

        }

    }


}

export default AutofillLateTerms;
