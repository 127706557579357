
import React from 'react'
import BackButton from "../common/BackButton";
import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";
import Axios from "axios";
import {API} from "../../api/APIClient";


class AutofillLogo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            alertModalOpen : false,
            alertModalTitle : null,
            alertModalMessage: null,
            alertModalButtons : [],
            image: null,
            submitMessage:""
        }
        this.fileChangedHandler = this.fileChangedHandler.bind(this);

    }

    componentDidMount() {

    }


    fileChangedHandler = (event) => {
        const file = event.target.files[0]

        if (file.name.length > 4)
        {
            var last3 = file.name.substr(file.name.length - 3).toUpperCase();
            var last4 = file.name.substr(file.name.length - 4).toUpperCase();

            if (last4 === "JPEG" || last3 === "JPG" || last3 === "PNG")
            {
                const name = event.target.name;

                this.setState({
                    [name]: file
                });
            } else {
                alert('Please choose an image file (jpg or png)');
            }

        } else {
            alert('Please choose an image file (jpg or png)');
        }
    }


    submitResetRequest = () => {
        this.showAlertModal(
            "Are you sure?",
            "Your logo will be removed and replaced with the default logo",
            [
                {
                    label : "Yes, reset",
                    onClick : () => {
                        this.setState({
                            loading: true
                        }, this.callRemoveLogo())

                        this.hideAlertModal()
                    },
                    className : "success"
                },
                {
                    label : "Cancel",
                    className : "cancel",
                    onClick : () => {
                        this.hideAlertModal()
                    },
                }
            ]
        )
    }

    callRemoveLogo() {
        const formData = new FormData();

        Axios
            .post(API.user.resetProfileImage, formData)
            .then((res) => {

                this.setState({
                    submitting:false,
                    submitMessage:""
                });
                if (res.data.success) {

                    var updatedUsr = this.props.user;
                    updatedUsr.formPictureUri = "";
                    this.props.userHandler(updatedUsr)

                 } else {

                    alert("Error sending image.  Please check your connection and try again");

                }
            })

    }


    submitChangeRequest = () => {

        if (this.state.image != null)
        {
            this.setState({
                submitting:true,
                submitMessage:"Uploading image..."
            });

            const formData = new FormData();
            formData.append('id', this.props.user.id);
            formData.append('image', this.state.image, this.state.image.name);

            Axios
                .post(API.user.uploadFormImage, formData)
                .then((res) => {

                    this.setState({
                        submitting:false,
                        submitMessage:""
                    });
                    if (res.data.success) {

                         var updatedUsr = this.props.user;
                        updatedUsr.formPictureUri =  res.data.data.formPictureUri;
                        this.props.userHandler(updatedUsr)

                    } else {

                        alert("Error sending image.  Please check your connection and try again");

                    }
                })

        } else {

             this.showAlertModal(
                "Please select an image",
                "",
                [

                    {
                        label : "OK",
                        className : "success",
                        onClick : () => {
                            this.hideAlertModal()
                        },
                    }
                ]
            )
        }


    }


    hideAlertModal() {
        this.setState({
            alertModalOpen : false
        });
    }

    showAlertModal(title, message, buttons) {
        this.setState({
            alertModalOpen : true,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        });
    }


    render() {

        var logoImg = "";

        if (this.props.user.formPictureUri === API.BASE_URL || this.props.user.formPictureUri === "")
        {
            logoImg = <div className="placeholder-icon" />;

        } else {
            logoImg = <img src={this.props.user.formPictureUri} alt="Logo" />;

        }


        return(<div className="main-wrap">
            <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>

            <div className="autofill">

                <div className="header">
                    <h1 className="head-title office">Form Logo</h1>
                </div>
                <div>This logo will be used for all forms and quotes generated by the app. Tap on the image to add or change your logo. Logos which are landscape orientation, approx 400x200 pixels. The grey box below indicates how the available space on the forms will be used to show the logo.</div>

                <div className="cell small-12">
                    <div className="logo-grey-container">
                        {logoImg}
                    </div>
                </div>
                <div className="cell small-12">
                    <span className="create-label center-text">Select a new file to change your logo:</span>
                    <label>

                        <input type="file" name="image" onChange={this.fileChangedHandler} accept=".jpg,.jpeg,.png"/>
                    </label>
                    <div className="cell small-12">&nbsp;</div>

                    {this.state.submitting ? <div>{this.state.submitMessage}</div> :
                        <div onClick={() => this.submitChangeRequest()} className="ga-button">Change Logo</div>
                    }
                     <div className="ga-button"  onClick={() => this.submitResetRequest()}>Reset Logo</div>
                </div>


            </div>
            <SimpleAlertDialog
                open={this.state.alertModalOpen}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}>
                {
                    this.state.alertModalButtons.map((button) => (
                        <ModalButton label={button.label} onClick={button.onClick}
                                     className={button.className}/>
                    ))
                }
            </SimpleAlertDialog>
        </div>);

    }


}

export default AutofillLogo;
