import React from 'react'
import BackButton from "../common/BackButton";
import Axios from "axios";
import {API} from "../../api/APIClient";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatTimestamp } from  "../../resources/Formatters";
import tick from '../../assets/img/tick.svg'
import cross from '../../assets/img/cross.svg'

const createEmptyQuote = () => ({

    quoteDate: Math.floor(Date.now() / 1000), // Current timestamp in seconds
    jobDescription: "",
    valid_for: "14 Days",
    isCustomerAddress: 1,
    title: "Quote"

});


class QuoteEditor extends React.Component {

    constructor(props) {
        super(props);

        var editing = true

        var customer = undefined
        if (this.props.customer !== undefined) {
             customer = this.props.customer
        }
        if (this.props.quote !== undefined) {
            editing = false
        }
        this.state = {
            editing: editing,
            customer: customer,

        }

        this.handleChange = this.handleChange.bind(this);


    }


    dateChanged = (date) => {
        const timestampInSeconds = Math.floor(date.getTime() / 1000);
        this.setState(prevState => ({
            quote: {
                ...prevState.quote,
                quoteDate: timestampInSeconds
            }
        }));

    }


    checkFinalisable() {
        var finalisable = false;
        if (this.state.quote.jobDescription !== undefined
            && this.state.quote.jobDescription.length > 0
            && this.state.quote.jobType !== undefined
            && this.state.quote.jobType.length > 0
            && this.state.quote.price !== undefined
            && this.state.quote.price.length > 0 ) {
            finalisable = true;
        }

        this.setState({
            finalisable: finalisable
        });
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

         if (name === "image1") {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState(prevState => ({
                    quote: {
                        ...prevState.quote,
                        photo1: reader.result.split(',')[1]  // Store only the base64 string part
                    }
                }));
            };
            reader.readAsDataURL(file);
        } else if (name === "image2") {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState(prevState => ({
                    quote: {
                        ...prevState.quote,
                        photo2: reader.result.split(',')[1]  // Store only the base64 string part
                    }
                }));
            };
            reader.readAsDataURL(file);
        } else if (name === "image3") {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState(prevState => ({
                    quote: {
                        ...prevState.quote,
                        photo3: reader.result.split(',')[1]  // Store only the base64 string part
                    }
                }));
            };
            reader.readAsDataURL(file);
        } else if (name === "image4") {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState(prevState => ({
                    quote: {
                        ...prevState.quote,
                        photo4: reader.result.split(',')[1]  // Store only the base64 string part
                    }
                }));
            };
            reader.readAsDataURL(file);
        } else if (name === "vatFlag") {
            this.setState({
                [name]: target.checked,
            }, function() {
                this.checkFinalisable();
            });

        } else if (name === "addressFlag") {
            this.setState({
                [name]: target.checked,
            });
            let isCA = target.checked ? 1 : 0;
            this.setState(prevState => ({
                quote: {
                    ...prevState.quote,
                    isCustomerAddress: isCA,
                }
            }));
        } else {
            this.setState(prevState => ({
                quote: {
                    ...prevState.quote,
                    [name]: value,
                }
            }), function() {
                this.checkFinalisable();
            });

        }


    }

    removeImage = (image) => {

        this.setState(prevState => ({
            quote: {
                ...prevState.quote,
                [image]: undefined,
            }
        }));
    }

    componentDidMount() {

        this.getPaymentSettings();
        this.getApplianceBrands();

        if (this.props.quote !== undefined) {
            this.getQuote();
        } else {
            var quote = createEmptyQuote()
            quote.customerId = this.props.customer.customerID
            quote.customer_name = this.props.customer.firstname + " " + this.props.customer.surname
            quote.addr1 = this.props.customer.address1
            quote.addr2 = this.props.customer.address2
            quote.town = this.props.customer.town
            quote.county = this.props.customer.county
            quote.postcode = this.props.customer.postcode
            this.setState({
                quote : quote,
                editing: true,
                addressFlag: true,
                finalisable: false
            }, function() {
                if (this.props.customer === undefined) {
                    this.getCustomer();
                }
            });
        }
    }

    getCustomer = () => {

        const formData = new FormData();
        formData.append('customerID', this.state.quote.customerId);

        Axios
            .post(API.office.getCustomer, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        customer : res.data.data
                    })
                }
            })
    }

    getPaymentSettings = () => {

        const formData = new FormData();
        formData.append('userId',this.props.user.id);


        Axios
            .post(API.office.getPaymentDetails, formData)
            .then((res) => {

                if (res.data.success) {

                    if (!this.state.finalisable)
                    {
                        this.setState(prevState => ({
                            quote: {
                                ...prevState.quote,
                                addVAT: res.data.data.vatDefault,
                                vatFlag: res.data.data.vatDefault === 1 ?  true : false,
                             }
                        }));
                    }

                    this.setState({
                        paymentSettings: res.data.data
                    })
                }

            }).catch(error => {


            console.log(error.response)

        });

    }

    getQuote = () => {

        var quoteID = undefined
        if (this.props.quote === undefined) {
            if (this.state.freshId === undefined){
                return;
            }
            quoteID = this.state.freshId;
        } else {
            quoteID = this.props.quote.id;
        }
        const formData = new FormData();
        formData.append('quoteID',quoteID);

        Axios
            .post(API.office.getQuote, formData)
            .then((res) => {

                if (res.data.success) {

                    const validForStr =  res.data.data.valid_for;
                    var validity = 14;
                    if (typeof validForStr === 'string') {
                        const valArr = validForStr.split(" ");
                        if (valArr.length > 0) {
                            const howLong = parseInt(valArr[0], 10);
                            if (!isNaN(howLong)) {
                                validity = howLong;
                            }
                        }
                    }
                    const currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);

                    const expirationDate = new Date((res.data.data.quoteDate + (validity * 86400)) * 1000);
                    expirationDate.setHours(0, 0, 0, 0);

                    const diffTime = expirationDate - currentDate;
                    const diffDays = diffTime / (1000 * 60 * 60 * 24);
                    const remainingDays = Math.ceil(diffDays);

                    res.data.data.expired = (currentDate >= expirationDate)
                    res.data.data.remainingDays = remainingDays
                    var addressFlag = res.data.data.isCustomerAddress === 1 ? true:false
                     this.setState({
                        quote: res.data.data,
                        loading:false,
                         addressFlag: addressFlag,
                         finalisable : true
                    }, function() {
                         if (this.props.customer === undefined) {
                             this.getCustomer();
                         }
                     });
                }

            }).catch(error => {


            console.log(error.response)

        });

    }

    getApplianceBrands = () => {
        const formData = new FormData();
        formData.append('typeId', 1);

        Axios
            .post(API.appliances.getApplianceBrands, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        boilerBrands : res.data.data
                    })
                }
            })
    }


    saveDetails = () => {

        if (this.state.finalisable) {
            this.setState((prevState) => {
                var updatedQuote = prevState.quote;
                updatedQuote.addVAT = prevState.vatFlag ? 1 : 0;
                return {
                    quote:  updatedQuote
                };

            });
            this.setState((prevState) => ({
                showDetailEditor: false
            }));
        }

    }


    finalise  = () => {

        if (this.state.finalisable) {


            this.setState((prevState) => ({
                showSpinner: true,
            }));

            const validForStr =  this.state.quote.valid_for;
            var validity = 14;
            if (typeof validForStr === 'string') {
                const valArr = validForStr.split(" ");
                if (valArr.length > 0) {
                    const howLong = parseInt(valArr[0], 10);
                    if (!isNaN(howLong)) {
                        validity = howLong;
                    }
                }
            }
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            const expirationDate = new Date((this.state.quote.quoteDate + (validity * 86400)) * 1000);
            expirationDate.setHours(0, 0, 0, 0);

            const formData = new FormData();
            formData.append('customerId', this.state.quote.customerId);

            formData.append('addVAT', this.state.quote.addVAT);
            formData.append('customer_name', this.state.quote.customer_name);
            formData.append('jobType', this.state.quote.jobType);
            formData.append('jobDescription', this.state.quote.jobDescription);
            formData.append('title', this.state.quote.title);

            formData.append('price', this.state.quote.price);
            formData.append('quoteDate', this.state.quote.quoteDate);
            formData.append('validFor', this.state.quote.valid_for);
            formData.append('isCustomerAddress', this.state.quote.isCustomerAddress);

            formData.append('addr1', this.state.quote.addr1);
            formData.append('addr2', this.state.quote.addr2);
            formData.append('county', this.state.quote.county);
            formData.append('postcode', this.state.quote.postcode);
            formData.append('town', this.state.quote.town);

            formData.append('instAddr1', this.state.quote.instAddr1);
            formData.append('instAddr2', this.state.quote.instAddr2);
            formData.append('instCounty', this.state.quote.instCounty);
            formData.append('instPostcode', this.state.quote.instPostcode);
            formData.append('instTown', this.state.quote.instTown);


            if(this.state.quote.photo1 != null) {
                formData.append('photo1', this.state.quote.photo1);
            }
            if(this.state.quote.photo2 != null) {
                formData.append('photo2', this.state.quote.photo2);
            }
            if(this.state.quote.photo3 != null) {
                formData.append('photo3', this.state.quote.photo3);
            }
            if(this.state.quote.photo4 != null) {
                formData.append('photo4', this.state.quote.photo4);
            }

            if(this.state.quote.jobType === "Boiler Installation") {
                formData.append("boiler", this.state.quote.boiler);
                formData.append("warranty", this.state.quote.warranty);
                formData.append("filter", this.state.quote.filter);
            }

            var endpoint = API.office.updateQuote
            if (this.state.quote.id === undefined) {
                endpoint = API.office.submitNewQuote
            } else {
                formData.append('quoteID', this.state.quote.id);
            }
            Axios
                .post(endpoint, formData)
                .then((res) => {


                    if (endpoint === API.office.submitNewQuote) {
                        this.setState((prevState) => ({
                            showSendResult: true,
                            showSpinner: false,
                            editing: false,
                            freshId : res.data.data
                        }), function() {
                            this.getQuote();
                        });
                    } else {
                        this.setState((prevState) => ({
                            showSendResult: true,
                            showSpinner: false,
                            editing: false


                        }), function() {
                            this.getQuote();
                        });
                    }

                })
                .catch((error) => {
                    this.setState((prevState) => ({
                        showSpinner: false
                    }));
                });
        }


    }


    showAddressEditor() {
        this.setState((prevState) => ({
            showAddressEditor: true
        }));
    }


    hideAddressEditor = () => {

        this.setState((prevState) => ({
            showAddressEditor: false
        }));
    }

    showDetailEditor() {

        var vatFlag = false;
        if (this.state.quote.addVAT === undefined
        && this.state.paymentSettings != null) {
            vatFlag = this.state.paymentSettings.vatDefault === 1

        } else {
            vatFlag = this.state.quote.addVAT === 1
        }

        this.setState((prevState) => ({
            vatFlag: vatFlag,
            showDetailEditor: true
        }));

    }

    hideDetailEditor = () => {
        this.setState((prevState) => ({
            showDetailEditor: false
        }), this.getQuote());
    }

    showBoilerEditor = () => {
        this.setState( (prevState) => ({
            showBoilerEditor: true
        }))
    }

    hideBoilerEditor = () => {
        this.setState( (prevState) => ({
            showBoilerEditor: false
        }))
    }

    viewPDF = () => {

        Axios.get(API.office.getQuotePDF + this.state.quote.id, {
            responseType: 'blob' // Important to set the response type to blob
        })
            .then((res) => {
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);

                this.setState({
                    pdfUrl: pdfUrl,
                    pdfBlob: pdfBlob
                });

            })
            .catch((error) => {
                console.error('Error fetching PDF:', error);
            });
    };



    closePDF() {

        this.setState({
            pdfUrl: undefined
        });
    }



    editMode = () => {

        this.setState({
            editing: true
        });
    }



    showDateEditor = () => {
        this.setState({ showDateEditor: true  });
    }

    hideDateEditor = () => {
        this.setState({ showDateEditor: false  });
    }



    hideSendResult = () => {
        this.setState((prevState) => ({
            showSendResult: false
        }));
    }

    showSendResult = () => {
        this.setState((prevState) => ({
            showSendResult: true
        }));
    }

    sanitizeFilename(addr) {
        return addr
            .replace(/[\/\\?*"<>\|:]/g, "_") // Replace invalid characters with "_"
            .trim(); // Remove leading/trailing spaces
    }


    downloadPDF = () => {
        if (this.state.pdfBlob) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(this.state.pdfBlob);
            link.download = `quote-${this.state.quote.id}.pdf`;
            if (this.props.customer && this.props.customer.address1){
                let addr = this.sanitizeFilename(this.props.customer.address1);
                link.download = `quote-${addr}.pdf`;
            }
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };



    render() {


        if (this.state.pdfUrl) {
            return(
                <div className="main-wrap">
                    <div className="cell small-12"><div  onClick={() => this.closePDF()} className="ga-button ga-back">
                        Back
                    </div></div>
                    <div className="cell small-12 no-pad">
                        <div className="content">
                            <div className="cell small-12">

                                <div className="ga-button" onClick={this.downloadPDF}>Download PDF</div>

                                <div style={{ width: "100%", height: "80vh", marginTop: "20px" }}>
                                    <iframe title="quto"
                                        src={this.state.pdfUrl}
                                        style={{ width: "100%", height: "100%", border: "none" }}
                                    ></iframe>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                        );
        } else{
            return(

            <div className="main-wrap">
                <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>
                <div className="cell small-12 no-pad">

                    <div className="quote">

                        <div className="cell small-12  ">

                        <div className="header">
                            <h1 className="head-title office">Quote</h1>
                        </div>
                        </div>


                        {this.state.quote ? <div className="content ">

                            {(this.state.editing === false && !this.state.quote.expired) &&

                                <div className="cell small-12" >
                                    <div className="cell small-12 valid no-pad"  style={{backgroundColor: "#86C46A",display: "flex", alignItems: "center",justifyContent: "space-between"}}>
                                    <div className="cell small-9"><h3 style={{color: "white"}}>Valid <img alt="tick" src={tick} height="16" style={{ filter: 'brightness(0) invert(1)' }}/></h3><div className="validity">Valid for {this.state.quote.remainingDays} more days</div></div>
                                     <div className="cell small-3"  style={{display: "flex", alignItems: "center",justifyContent: "flex-end"}}>
                                         <div  onClick={() => this.props.createInvoiceHandler(this.state.quote)} className="ga-button" style={{border: "1px solid white"}}>Turn into Invoice</div>
                                     </div>

                                    </div>
                                </div>
                            }

                            {(this.state.editing === false && this.state.quote.expired) &&


                                <div className="cell small-12">
                                    <div className="cell small-12 valid no-pad" style={{backgroundColor: "#D85946",display: "flex", alignItems: "center",justifyContent: "space-between"}}>
                                            <div className="cell small-9"><h3 style={{color: "white"}}>Expired  <img  alt="cross" src={cross} height="16" style={{ filter: 'brightness(0) invert(1)' }}/></h3><div className="validity">Expired {this.state.quote.remainingDays*-1} days ago</div></div>
                                            <div className="cell small-3"  style={{display: "flex", alignItems: "center",justifyContent: "flex-end"}}><div onClick={this.editMode} className="ga-button" >Update this quote</div>
                                        </div>

                                    </div>
                                </div>
                            }

                            <div className="cell  small-12 no-pad " style={{display: "flex", alignItems: 'stretch' }}>


                                {this.state.customer && <div className="cell small-6 " style={{ flex: 1}}>
                                    <div className="item-header">Customer </div>
                                        <div className="item-text">{this.state.customer.firstname} {this.state.customer.surname}</div>
                                        <div className="item-text">{this.state.customer.address1} {this.state.customer.address2} {this.state.customer.town} {this.state.customer.county} {this.state.customer.postcode} </div>
                                </div>}
                                <div className="cell small-6"  style={{  flex: 1 }}>
                                        <div className="item-header">Date {this.state.editing && <div className="ga-button edit-button" onClick={this.showDateEditor}></div>}</div>
                                        <div className="item-text">{formatTimestamp(this.state.quote.quoteDate)}</div>
                                        <div className="item-text">The job address is the same as the customer address
                                            <input type="checkbox" name="addressFlag" checked={this.state.addressFlag} onClick={this.handleChange}/>
                                            <span className="checkmark"></span>
                                        </div>

                                </div>



                            </div>


                                {!this.state.addressFlag &&

                                    <div className="cell  small-12 ">
                                        <div className="item-header">Job Address {this.state.editing && <div className="ga-button edit-button" onClick={() => this.showAddressEditor()}></div>}</div>
                                        <div className="item-text">
                                            <div>{this.state.quote.instAddr1}</div>
                                            <div>{this.state.quote.instAddr2}</div>
                                            <div>{this.state.quote.instTown}</div>
                                            <div>{this.state.quote.instCounty}</div>
                                            <div>{this.state.quote.instPostcode}</div>

                                        </div>
                                    </div>

                                }
                            {this.state.finalisable ?

                            <div className="cell  small-12 ">
                                <div className="item-header">Quote Details {this.state.editing && <div className="ga-button edit-button" onClick={() => this.showDetailEditor()}></div>}</div>
                                <div className="item-text">

                                    <div>Quote: {this.state.quote.jobType}</div>

                                    <div>{this.state.quote.jobDescription}</div>

                                    <div className="grid-container">

                                        <div className="cell small-3 ">{(this.state.quote.photo1 && this.state.quote.photo1.length > 10) && <img className="responsive-img"  src={`data:image/jpeg;base64,${this.state.quote.photo1}`} alt=" 1" />}</div>
                                        <div className="cell small-3 ">{(this.state.quote.photo2 && this.state.quote.photo2.length > 10) && <img className="responsive-img"  src={`data:image/jpeg;base64,${this.state.quote.photo2}`} alt=" 2" />}</div>
                                        <div className="cell small-3 ">{(this.state.quote.photo3 && this.state.quote.photo3.length > 10) && <img className="responsive-img"  src={`data:image/jpeg;base64,${this.state.quote.photo3}`} alt=" 3" />}</div>
                                        <div className="cel  small-3 ">{(this.state.quote.photo4 && this.state.quote.photo4.length > 10) && <img className="responsive-img"  src={`data:image/jpeg;base64,${this.state.quote.photo4}`} alt=" 4" />}</div>
                                    </div>
                                    <div>Add VAT? {this.state.quote.addVAT === 1 ? "Yes" : "No"} £{this.state.quote.price}</div>

                                    <div>Quote valid for {this.state.quote.valid_for}</div>

                                </div>

                            </div>

                                :
                                <div className="cell small-12">
                                <div className="cell small-12 ga-button" onClick={() => this.showDetailEditor()}>
                                    Add Quote Details &gt;
                                </div>
                                </div>
                            }

                                {(this.state.finalisable && this.state.quote.jobType === "Boiler Installation" && this.state.quote.boiler !== undefined) ?
                                    <div className="cell  small-12 ">
                                        <div className="item-header">Boiler Details {this.state.editing && <div className="ga-button edit-button" onClick={() => this.showBoilerEditor()}></div>}</div>
                                        <div className="item-text">

                                            <div>Brand: {this.state.quote.boiler}</div>

                                            <div>Warranty: {this.state.quote.warranty}</div>

                                            <div>Filter: {this.state.quote.filter}</div>

                                        </div>

                                    </div>

                                    :

                                    (this.state.finalisable && this.state.quote.jobType === "Boiler Installation" && this.state.quote.boiler === undefined) &&
                                    <div className="cell small-12" style={{fontSize:"1.2em"}} onClick={() => this.showBoilerEditor()}>
                                        Add Boiler Details &nbsp;&nbsp;&nbsp; &gt;
                                    </div>

                                }




                                <div className="cell small-12"></div>
                            {this.state.editing ?
                                <div>
                                    <div className="cell small-12 buttons">
                                        <div onClick={this.finalise} className="ga-button"  style={{width: "300px", opacity: this.state.finalisable ? 1 : 0.5}}>Finalise Quote</div>&nbsp;
                                        <div onClick={this.props.backHandler} className="ga-button" style={{width: "300px",background:"#999"}}>Cancel</div>
                                    </div>
                                </div>

                                :

                                <div>
                                    <div className="cell small-12 buttons">
                                        <div onClick={this.viewPDF} className="ga-button"  style={{width: "300px"}}>View PDF</div>&nbsp;
                                        <div onClick={this.editMode} className="ga-button"  style={{width: "300px"}}>Edit Quote</div>
                                    </div>
                                </div>
                            }


                        </div>

                            :

                            <div>Loading...</div>
                        }
                    </div>
                </div>
                <div className="cell small-12"></div>




                {this.state.showDateEditor && (
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Select a Date</h2>

                            <label>
                                <DatePicker
                                    className="edit-field"
                                    selected={this.state.quote.quoteDate*1000}
                                    onChange={this.dateChanged}
                                    name="dateTime"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select a date"
                                />
                            </label>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>                            <div>&nbsp;</div>
                            <div className="cell small-12 centered no-side-pad"><div onClick={this.hideDateEditor} className="ga-button modal-button" >OK</div></div>

                            <div>&nbsp;</div>



                        </div>
                    </div>
                )}



                {this.state.showAddressEditor && (
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Job Address</h2>

                            <div className="cell small-12 no-pad bold-text" >Address Line 1</div>
                            <label>
                                <input className="edit-field" type="text" name="instAddr1"  value={this.state.quote.instAddr1} onChange={this.handleChange}/>
                            </label>
                            <div className="cell small-12  "> </div>

                            <div className="cell small-12 no-pad bold-text" >Address Line 2</div>
                            <label>
                                <input className="edit-field" type="text" name="instAddr2"  value={this.state.quote.instAddr2} onChange={this.handleChange}/>
                            </label>

                            <div className="cell small-12  "> </div>

                            <div className="cell small-12 no-pad bold-text" >Town/City</div>
                            <label>
                                <input className="edit-field" type="text" name="instTown"  value={this.state.quote.instTown} onChange={this.handleChange}/>
                            </label>
                            <div className="cell small-12  "> </div>

                            <div className="cell small-12 no-pad bold-text" >County</div>
                            <label>
                                <input className="edit-field" type="text" name="instCounty"  value={this.state.quote.instCounty} onChange={this.handleChange}/>
                            </label>
                            <div className="cell small-12  "> </div>

                            <div className="cell small-12 no-pad bold-text" >Postcode</div>
                            <label>
                                <input className="edit-field" type="text" name="instPostcode"  value={this.state.quote.instPostcode} onChange={this.handleChange}/>
                            </label>

                            <div className="cell small-12 centered"><div onClick={this.hideAddressEditor} className="ga-button"  style={{width: "80%"}}>Confirm</div></div>


                            &nbsp;


                        </div>
                    </div>
                )}



                {this.state.showDetailEditor && (
                    <div className="modal">
                        <div className="modal-content" style={{width:"850px"}}>
                            <h2>Quote Details</h2>


                            <div className="cell small-12 no-pad form-title">Job type:</div>
                            <select className="weeks" value={this.state.quote.jobType} name="jobType" onChange={this.handleChange}>
                                <option value="">Select Job Type</option>
                                <option value="Breakdown / Repair">Breakdown / Repair</option>
                                <option value="Boiler Installation">Boiler Installation</option>
                                <option value="Service">Service</option>
                                <option value="Bathroom - Full Refurb">Bathroom - Full Refurb</option>
                                <option value="Bathroom - Part Refurb">Bathroom - Part Refurb</option>
                                <option value="Kitchen - Full Refurb">Kitchen - Full Refurb</option>
                                <option value="Kitchen - Part Refurb">Kitchen - Part Refurb</option>
                                <option value="Other">Other</option>
                            </select>


                            <div className="cell small-12 no-pad bold-text form-title">Job Description</div>
                            <label>
                                <textarea className="msg" type="text" name="jobDescription" placeholder="" onChange={this.handleChange}>{this.state.quote.jobDescription}</textarea>
                            </label>
                            <div className="cell small-12  "> </div>

                            <div className="cell small-12 no-pad  "><div className="bold-text form-title">Photos (optional)</div></div>


                                    <div className="cell small-3 no-pad">{(this.state.quote.photo1 && this.state.quote.photo1.length > 10) ?
                                        <span>Tap to delete<br /><img width="100%" className="responsive-img"  src={`data:image/jpeg;base64,${this.state.quote.photo1}`} alt=" 1" onClick={() => this.removeImage("photo1")} /></span>
                                        :
                                        <input type="file" name="image1" onChange={this.handleChange} accept=".jpg,.jpeg,.png"/>
                                    }
                                    </div>
                                    <div className="cell small-3  no-pad">{(this.state.quote.photo2 && this.state.quote.photo2.length > 10) ?
                                        <span>Tap to delete<br /><img width="100%" className="responsive-img"  src={`data:image/jpeg;base64,${this.state.quote.photo2}`} alt=" 2"  onClick={() => this.removeImage("photo2")} /></span>
                                        :
                                        <input type="file" name="image2" onChange={this.handleChange} accept=".jpg,.jpeg,.png"/>
                                    }
                                    </div>
                                    <div className="cell small-3  no-pad">{(this.state.quote.photo3 && this.state.quote.photo3.length > 10) ?
                                        <span>Tap to delete<br /><img width="100%" className="responsive-img"  src={`data:image/jpeg;base64,${this.state.quote.photo3}`} alt=" 3"  onClick={() => this.removeImage("photo3")} /></span>
                                        :
                                        <input type="file" name="image3" onChange={this.handleChange} accept=".jpg,.jpeg,.png"/>
                                    }
                                    </div>
                                    <div className="cell small-3  no-pad">{(this.state.quote.photo4 && this.state.quote.photo4.length > 10) ?
                                        <span>Tap to delete<br /><img width="100%" className="responsive-img"  src={`data:image/jpeg;base64,${this.state.quote.photo4}`} alt=" 4"  onClick={() => this.removeImage("photo4")} /></span>
                                        :
                                        <input type="file" name="image4" onChange={this.handleChange} accept=".jpg,.jpeg,.png"/>
                                    }
                                    </div>



                            <div className="cell small-12  "> </div>

                            <div className="cell small-12 no-pad bold-text form-title" >Quote Price</div>
                            <label>
                                <input className="edit-field" type="text" name="price"  value={this.state.quote.price} onChange={this.handleChange}/>
                            </label>
                            <div className="cell small-12  "> </div>

                            <label className="cell small-12 autoemails no-pad bold-text form-title">Add on VAT?
                                <input type="checkbox" name="vatFlag" checked={this.state.vatFlag} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>


                            <div className="cell small-12 no-pad form-title">Valid for:</div>
                            <select className="weeks" value={this.state.quote.valid_for} name="valid_for" onChange={this.handleChange}>
                                <option value="7 Days">7 Days</option>
                                <option value="14 Days">14 Days</option>
                                <option value="21 Days">21 Days</option>
                                <option value="28 Days">28 Days</option>

                            </select>


                            <div className="cell small-12 no-pad form-title">Quote or Estimate:</div>
                            <select className="weeks" value={this.state.quote.title} name="title" onChange={this.handleChange}>
                                <option value="Quote">Quote</option>
                                <option value="Estimate">Estimate</option>
                            </select>

                                 <div className="cell small-12 centered"><div className="ga-button"  onClick={this.saveDetails} style={{width: "300px", opacity: this.state.finalisable ? 1 : 0.5}}>Confirm</div></div>

                             <div className="cell small-12 centered"><div onClick={this.hideDetailEditor} className="ga-button" style={{width: "300px",background:"#999"}}>Cancel</div></div>
                            &nbsp;

                        </div>
                    </div>
                )}

                {this.state.showBoilerEditor && (
                    <div className="modal">
                        <div className="modal-content" style={{width:"850px"}}>
                            <h2>Boiler Details</h2>


                            <div className="cell small-12">Boiler Brand:</div>
                            <select className="weeks" value={this.state.quote.boiler} name="boiler" onChange={this.handleChange}>
                                <option value="">Select Boiler Brand</option>
                                {this.state.boilerBrands !== undefined &&
                                this.state.boilerBrands.map((brand) => {
                                        return (
                                            <option value={brand.name}>{brand.name}</option>
                                        )
                                    })
                                }
                            </select>

                            <div className="cell small-12">Included Warranty:</div>
                            <select className="weeks" value={this.state.quote.warranty} name="warranty" onChange={this.handleChange}>
                                <option value="">Select Included Warranty</option>
                                <option value="1 Year">1 Year</option>
                                <option value="2 Years">2 Years</option>
                                <option value="3 Years">3 Years</option>
                                <option value="4 Years">4 Years</option>
                                <option value="5 Years">5 Years</option>
                                <option value="6 Years">6 Years</option>
                                <option value="7 Years">7 Years</option>
                                <option value="8 Years">8 Years</option>
                                <option value="9 Years">9 Years</option>
                                <option value="10 Years">10 Years</option>
                                <option value="11 Years">11 Years</option>
                                <option value="12 Years">12 Years</option>
                                <option value="13 Years">13 Years</option>
                                <option value="14 Years">14 Years</option>
                                <option value="15 Years">15 Years</option>
                                <option value="16 Years">16 Years</option>
                                <option value="17 Years">17 Years</option>
                                <option value="18 Years">18 Years</option>
                                <option value="19 Years">19 Years</option>
                                <option value="20 Years">20 Years</option>
                            </select>

                            <div className="cell small-12">Filter:</div>
                            <select className="weeks" value={this.state.quote.filter} name="filter" onChange={this.handleChange}>
                                <option value="">Select Filter</option>
                                <option value="ADEY">ADEY</option>
                                <option value="Alternic">Alternic</option>
                                <option value="BoilerMag">BoilerMag</option>
                                <option value="Fernox">Fernox</option>
                                <option value="Grant">Grant</option>
                                <option value="Sentinel">Sentinel</option>
                                <option value="Spirotech">Spirotech</option>
                                <option value="Manufacturers Own">Manufacturers Own</option>
                                <option value="Other">Other</option>
                            </select>

                            <div className="cell small-12 centered"><div className="ga-button"  onClick={this.hideBoilerEditor} style={{width: "300px", opacity: this.state.finalisable ? 1 : 0.5}}>Confirm</div></div>

                            <div className="cell small-12 centered"><div onClick={this.hideBoilerEditor} className="ga-button" style={{width: "300px",background:"#999"}}>Cancel</div></div>
                            &nbsp;

                        </div>
                    </div>
                )}


                {this.state.showSpinner && (
                    <div className="modal">
                        <div className="modal-content" style={{textAlign:"center"}} >
                            <h2>Please wait</h2>
                            &nbsp;

                            <div>Saving quote details... </div>

                            &nbsp;



                        </div>
                    </div>
                )}

                {this.state.showSendResult && (
                    <div className="modal">
                        <div className="modal-content" style={{textAlign:"center"}} >
                            <h2>Quote Saved</h2>
                            <div>Quote saved successfully</div>


                            <div className="cell small-12 centered"><div onClick={this.hideSendResult} className="ga-button"  style={{width: "80%"}}>OK</div></div>


                            &nbsp;


                        </div>
                    </div>
                )}


            </div>
        );
        }
    }
}
export default QuoteEditor;