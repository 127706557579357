import React from 'react'
import BackButton from "../common/BackButton"
import { API } from '../../api/APIClient'
import Axios from 'axios'
import Payment from '../settings/Payment'
import Amex from '../../assets/img/amex.svg'
import Mastercard from '../../assets/img/mastercard.svg'
import Visa from '../../assets/img/visa.svg'
import Stripe from '../../assets/img/powered_by_stripe.svg'
import Tick from '../../assets/img/tick.svg'
import Devices from '../../assets/img/apple-devices2.jpg'
import moment from 'moment';


class Subscription extends React.Component {

    constructor(props) {
        super(props);

        var paysel = null;
        if (this.props.term != undefined)
        {
            paysel = this.props.term;
        }
        this.state = {
            subDetails: null,
            paymentSelected: paysel
        };
    }


    componentDidMount() {
        this.getLatestDetails();
    }



    getLatestDetails() {

        const formData = new FormData();

        Axios
            .post(API.user.getSubscriptionData, formData)
            .then((res) => {

                if (res.data.success ) {

                    this.setState({subDetails: res.data.data})
                }
            });
    }



    refreshSubDetails()
    {
        this.setState({subDetails: null}, () => this.checkForNewSub());
    }


    checkForNewSub()
    {
        const formData = new FormData();

        Axios
            .post(API.user.getSubscriptionData, formData)
            .then((res) => {

                if (res.data.success ) {

                    this.setState({subDetails: res.data.data})

                    var updatedUsr = this.props.user
                    updatedUsr.state = res.data.data.userType
                    this.props.userHandler(updatedUsr)
                }
            });
    }

    subscribeClicked(term)
    {
        this.setState(
            {
                paymentSelected: term})
        ;
    }

    render() {

        var content = "";

        if (this.state.subDetails == null)
        {
            content = <div>
                Loading subscription details...
            </div>;



        } else {


            if (this.state.subDetails.userType == 1) {


                let dateexpires =  moment(this.state.subDetails.expires, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');

                content = <div className="create-box">
                    <div className="cell small-12">
                        <h2>Thanks for using Gas App Uk!</h2>
                        {/*<div className="cell small-12">
                            <span className="sub-info">Your subscription renews on: <span className="subdate">{dateexpires}</span></span>
                        </div>*/}
                       
                        
                        <div className="main-wrap pricing">
                            <div className="main-wrap what-you-get">
                                <div className="cell small-12">
                                  <h2>What you get with Gas App Uk</h2>
                                  <p>As a loyal Gas App Uk user, you have access to the following content:</p>
                                  <div className="main-wrap features">

                                    

                                    <div className="feature cell small-12 medium-6 large-4">
                                      <div className="tick-wrap">
                                        <img src={Tick} alt="" />
                                        <div className="tick-text">
                                          <span className="tick-title">Bespoke Quoting Software</span>
                                          <span className="tick-note">A quoting tool that takes the strain out of managing your business by enabling you to email quotes containing your business details and logo to customers in less than a few minutes.</span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="feature cell small-12 medium-6 large-4">
                                      <div className="tick-wrap">
                                        <img src={Tick} alt="" />
                                        <div className="tick-text">
                                          <span className="tick-title">Digital Forms</span>
                                          <span className="tick-note">Domestic job sheets, gas inspection records, landlord gas safety records, legionnaire risk assessments, warning notices and much more. Easily add your company details and logo and quickly email the relevant forms to your customers or print them off.</span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="feature cell small-12 medium-6 large-4">
                                      <div className="tick-wrap">
                                        <img src={Tick} alt="" />
                                        <div className="tick-text">
                                          <span className="tick-title">PDF Reference Library</span>
                                          <span className="tick-note">A library full of important PDF documents to refer back to when you need to. The library includes servicing checklists, meter docs, unsafe procedures, gas rating, ACS refresher and much more.</span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="feature cell small-12 medium-6 large-4">
                                      <div className="tick-wrap">
                                        <img src={Tick} alt="" />
                                        <div className="tick-text">
                                          <span className="tick-title">Thousands of Manuals and MI’s</span>
                                          <span className="tick-note">Manuals for both domestic and commercial appliances, including current, backdated and even obsolete manuals.</span>
                                        </div>
                                      </div>
                                    </div>

                                    

                                    <div className="feature cell small-12 medium-6 large-4">
                                      <div className="tick-wrap">
                                        <img src={Tick} alt="" />
                                        <div className="tick-text">
                                          <span className="tick-title">Toolbox</span>
                                          <span className="tick-note">Pipe sizing calculator, gas rating, ventilation and purge calculators, water PPM calculator, direct manufacturer quick dial contact numbers and video tutorials.</span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="feature cell small-12 medium-6 large-4">
                                      <div className="tick-wrap">
                                        <img src={Tick} alt="" />
                                        <div className="tick-text">
                                          <span className="tick-title">Live Fault Finding</span>
                                          <span className="tick-note">A community of engineers working together to help each other diagnose boiler repairs safely and quickly on the job.</span>
                                        </div>
                                      </div>
                                    </div>

                                    

                                    <div className="feature cell small-12 medium-6 large-4">
                                      <div className="tick-wrap">
                                        <img src={Tick} alt="" />
                                        <div className="tick-text">
                                          <span className="tick-title">Breaktime</span>
                                          <span className="tick-note">Interact and share stories from your work with other members of our community.</span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="feature cell small-12 medium-6 large-4">
                                      <div className="tick-wrap">
                                        <img src={Tick} alt="" />
                                        <div className="tick-text">
                                          <span className="tick-title">News</span>
                                          <span className="tick-note">The latest newsroom content in real-time from key publications including PHAM News, Installer and PHPI.</span>
                                        </div>
                                      </div>
                                    </div>

                                    

                                    <div className="feature cell small-12 medium-6 large-4">
                                      <div className="tick-wrap">
                                        <img src={Tick} alt="" />
                                        <div className="tick-text">
                                          <span className="tick-title">Horror Show Galleries</span>
                                          <span className="tick-note">Monthly giveaway for the best (worst) picture of the most shocking work done by rogue traders that you’ve come across on the job.</span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="feature cell small-12 medium-6 large-4">
                                      <div className="tick-wrap">
                                        <img src={Tick} alt="" />
                                        <div className="tick-text">
                                          <span className="tick-title">Competitions</span>
                                          <span className="tick-note">Running continuously with fantastic prizes.</span>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>;



            } else if (this.state.subDetails.userType == 2) {


                if (this.state.paymentSelected)
                {
                    content = <div className="create-box">
                        <div className="cell small-12">
                            <h2>Subscribe Today!</h2>
                            <div className="cell small-12">
                                Please click the Pay Now button to start the payment process:
                            </div>

                            <div className="add-space"></div>
                            {this.state.paymentSelected ?

                                <Payment email={this.props.user.email} term={this.state.paymentSelected} refreshSubDetails={() => this.refreshSubDetails()}/>

                                :
                                <div>
                                    <div>Purchase a 1 year auto-renewing subscription for just £25, or pay monthly instead at only £3 per month.  Simply select your preferred option below.</div>

                                    <div className="cell small-12">

                                        <div onClick={() => this.subscribeClicked("year")} className="ga-button">Yearly Subscription - £25</div>
                                        <div onClick={() => this.subscribeClicked("month")} className="ga-button">Monthly Subscription - £3</div>
                                    </div>
                                </div>
                                }

                        </div>
                    </div>
                } else {
                    let dateexpires =  moment(this.state.subDetails.expires, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');

                    content = <div className="create-box">
                        <div className="cell small-12">
                            <h2>Thanks for being a subscriber!</h2>
                            <div className="cell small-12">
                                <span className="sub-info">Your free trial ends on: <span className="subdate">{dateexpires}</span></span>
                            </div>
                            <div className="cell small-12">
                                Subscribe today to continue using the following features after your free trial!
                            </div>

                            <div className="main-wrap pricing">


                                


                                <div className="main-wrap what-you-get">
                                    <div className="cell small-12">
                                        <h2>What you get</h2>
                                        <p>All Gas App Uk subscriptions come with all of these amazing features</p>
                                        <div className="main-wrap features">



                                            <div className="feature cell small-12 medium-6 large-4">
                                                <div className="tick-wrap">
                                                    <img src={Tick} alt="" />
                                                    <div className="tick-text">
                                                        <span className="tick-title">Bespoke Quoting Software</span>
                                                        <span className="tick-note">A quoting tool that takes the strain out of managing your business by enabling you to email quotes containing your business details and logo to customers in less than a few minutes.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feature cell small-12 medium-6 large-4">
                                                <div className="tick-wrap">
                                                    <img src={Tick} alt="" />
                                                    <div className="tick-text">
                                                        <span className="tick-title">Digital Forms</span>
                                                        <span className="tick-note">Domestic job sheets, gas inspection records, landlord gas safety records, legionnaire risk assessments, warning notices and much more. Easily add your company details and logo and quickly email the relevant forms to your customers or print them off.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feature cell small-12 medium-6 large-4">
                                                <div className="tick-wrap">
                                                    <img src={Tick} alt="" />
                                                    <div className="tick-text">
                                                        <span className="tick-title">PDF Reference Library</span>
                                                        <span className="tick-note">A library full of important PDF documents to refer back to when you need to. The library includes servicing checklists, meter docs, unsafe procedures, gas rating, ACS refresher and much more.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feature cell small-12 medium-6 large-4">
                                                <div className="tick-wrap">
                                                    <img src={Tick} alt="" />
                                                    <div className="tick-text">
                                                        <span className="tick-title">Thousands of Manuals and MI’s</span>
                                                        <span className="tick-note">Manuals for both domestic and commercial appliances, including current, backdated and even obsolete manuals.</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="feature cell small-12 medium-6 large-4">
                                                <div className="tick-wrap">
                                                    <img src={Tick} alt="" />
                                                    <div className="tick-text">
                                                        <span className="tick-title">Toolbox</span>
                                                        <span className="tick-note">Pipe sizing calculator, gas rating, ventilation and purge calculators, water PPM calculator, direct manufacturer quick dial contact numbers and video tutorials.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feature cell small-12 medium-6 large-4">
                                                <div className="tick-wrap">
                                                    <img src={Tick} alt="" />
                                                    <div className="tick-text">
                                                        <span className="tick-title">Live Fault Finding</span>
                                                        <span className="tick-note">A community of engineers working together to help each other diagnose boiler repairs safely and quickly on the job.</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="feature cell small-12 medium-6 large-4">
                                                <div className="tick-wrap">
                                                    <img src={Tick} alt="" />
                                                    <div className="tick-text">
                                                        <span className="tick-title">Breaktime</span>
                                                        <span className="tick-note">Interact and share stories from your work with other members of our community.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feature cell small-12 medium-6 large-4">
                                                <div className="tick-wrap">
                                                    <img src={Tick} alt="" />
                                                    <div className="tick-text">
                                                        <span className="tick-title">News</span>
                                                        <span className="tick-note">The latest newsroom content in real-time from key publications including PHAM News, Installer and PHPI.</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="feature cell small-12 medium-6 large-4">
                                                <div className="tick-wrap">
                                                    <img src={Tick} alt="" />
                                                    <div className="tick-text">
                                                        <span className="tick-title">Horror Show Galleries</span>
                                                        <span className="tick-note">Monthly giveaway for the best (worst) picture of the most shocking work done by rogue traders that you’ve come across on the job.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feature cell small-12 medium-6 large-4">
                                                <div className="tick-wrap">
                                                    <img src={Tick} alt="" />
                                                    <div className="tick-text">
                                                        <span className="tick-title">Competitions</span>
                                                        <span className="tick-note">Running continuously with fantastic prizes.</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="main-wrap what-its-on">
                                    <div className="cell small-12">
                                        <h2>Available on all platforms</h2>
                                        <img src={Devices} alt="" />
                                    </div>
                                </div>



                                <div className="clearfix"></div>
                            </div>
                        </div>

                    </div>;
                }




            } else {
                ////pk_live_09ruKK0qXpwnqFzI5xVnHcbM  /12 month prod_FPSFk5pkCz5v9N  / 1 month prod_FPSGuTBm5Xlq7T

                content = <div className="create-box">
                    <div className="cell small-12">

                        <div className="cell small-12">
                            Please click the Pay Now button to start the payment process:
                        </div>

                        <div className="add-space"></div>
                            {this.state.paymentSelected ?

                                <Payment email={this.props.user.email} term={this.state.paymentSelected} refreshSubDetails={() => this.refreshSubDetails()}/>

                                :
                                <div>
                                    <div>Purchase a 1 year auto-renewing subscription for just £25, or pay monthly instead at only £3 per month.  Simply select your preferred option below.</div>

                                    <div className="cell small-12">

                                        <div onClick={() => this.subscribeClicked("year")} className="ga-button">Yearly Subscription - £25</div>
                                        <div onClick={() => this.subscribeClicked("month")} className="ga-button">Monthly Subscription - £3</div>
                                    </div>
                                </div>
                                }

                    </div>
                </div>

            }

        }

        return ( <div className="main-wrap">

            <div className="cell small-12 header">
                <BackButton backHandler={this.props.backHandler} />
                <h1 className="head-title toolbox">My Account > FREE Access</h1>
            </div>
                   {content}
        </div>);

    }
}

export default Subscription;
