
import React from 'react'
import BackButton from "../common/BackButton";
import Axios from "axios";
import {API} from "../../api/APIClient";
import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";


class AutofillInvoiceQuote extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            alertModalOpen : false,
            alertModalTitle : null,
            alertModalMessage: null,
            alertModalButtons : [],
            vatNumber: "",
            websiteURL: "",
            businessEmail: "",
            cisNumber: "",
            vatDefault: 0,
            vatFlag: false
        }

        this.handleChange = this.handleChange.bind(this);
    }





    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === "vatFlag") {
            this.setState({
                [name]: target.checked,
                vatDefault: target.checked ? 1 : 0,
            });
        } else {
            this.setState({
                [name]: value
            });

        }

    }


    componentDidMount() {

        this.getPaymentDetails();
    }

    getPaymentDetails = () => {
        Axios.get(API.autoFill.getPaymentDetails)
            .then((res) => {
                if (res.data.success) {

                    this.setState({
                        companyNumber: res.data.data.companyNumber,
                        vatNumber: res.data.data.vatNumber,
                        websiteURL: res.data.data.websiteURL,
                        businessEmail: res.data.data.businessEmail,
                        cisNumber: res.data.data.cisNumber,
                        vatDefault : res.data.data.vatDefault,
                        vatFlag : res.data.data.vatDefault === 1 ? true : false,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };


    hideAlertModal() {
        this.setState({
            alertModalOpen : false
        });
    }

    showAlertModal(title, message, buttons) {
        this.setState({
            alertModalOpen : true,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        });
    }


    saveChanges() {

        const formData = new FormData();
        formData.append('companyNumber', this.state.companyNumber);
        formData.append('vatNumber', this.state.vatNumber);
        formData.append('websiteURL', this.state.websiteURL);
        formData.append('businessEmail', this.state.businessEmail);
        formData.append('cisNumber', this.state.cisNumber);
        formData.append('vatDefault', this.state.vatDefault);

        Axios
            .post(API.autoFill.updateBusinessDetails, formData)
            .then((res) => {

                this.showAlertModal(
                    "Details Saved!",
                    "Invoice/quote details have been saved.",
                    [
                        {
                            label : "OK ",
                            onClick : () => {
                                this.hideAlertModal() },
                            className : "success"
                        }

                    ]
                )


            }).catch(error => {

            console.log(error.response)
            if (error.response.data.error) {

                alert(error.response.data.error)
            } else {
                alert("Could not submit terms.  Please check connection and try again.")
            }
        });


    }




    render() {

        if (this.state.loading){
            return(<div className="main-wrap">
                <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>

                <div className="cell small-12">
                    <div className="">Loading...</div>
                </div>
            </div>);

        } else {
            return(<div className="main-wrap">
                <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>

                <div className="autofill">
                    <div className="cell small-12">

                        <div className="header">
                            <h1 className="head-title office">Invoice &amp; Quote Details</h1>
                        </div>
                    </div>

                    <div className="cell small-4">
                        <div className="cell small-12 no-pad bold-text input-title autoemails" >
                        <label>VAT on by default
                            <input type="checkbox" name="vatFlag" checked={this.state.vatFlag} onClick={this.handleChange}/>
                            <span className="checkmark"></span>
                        </label>
                        </div>
                     </div>
                    <div className="cell small-4">
                        <div className="cell small-12 no-pad bold-text input-title" >Company Number</div>
                        <label>
                            <input className="edit-field" type="text" name="companyNumber"  value={this.state.companyNumber} onChange={this.handleChange}/>
                        </label>
                    </div>
                    <div className="cell small-4">
                        <div className="cell small-12 no-pad bold-text input-title" >VAT Number</div>
                        <label>
                            <input className="edit-field" type="text" name="vatNumber"  value={this.state.vatNumber} onChange={this.handleChange}/>
                        </label>
                    </div>


                    <div className="cell small-4">
                        <div className="cell small-12 no-pad bold-text input-title" >Business Email Address</div>
                        <label>
                            <input className="edit-field" type="text" name="businessEmail"  value={this.state.businessEmail} onChange={this.handleChange}/>
                        </label>
                    </div>
                    <div className="cell small-4">
                        <div className="cell small-12 no-pad bold-text input-title" >Business Website Address</div>
                        <label>
                            <input className="edit-field" type="text" name="websiteURL"  value={this.state.websiteURL} onChange={this.handleChange}/>
                        </label>
                    </div>
                    <div className="cell small-4">
                        <div className="cell small-12 no-pad bold-text input-title" >CIS Number</div>
                        <label>
                            <input className="edit-field" type="text" name="cisNumber"  value={this.state.cisNumber} onChange={this.handleChange}/>
                        </label>
                    </div>


                </div>
                <div className="cell small-12  " >
                </div>
                <div className="cell small-4 no-pad" >&nbsp;
                </div>
                <div className="cell small-4 no-pad" >
                    <div className="ga-button" style={{width: "100%", marginRight:"0px"}} onClick={() => this.saveChanges()}>Save</div>
                </div>
                <div className="cell small-4 no-pad" >&nbsp;
                </div>
                <SimpleAlertDialog
                    open={this.state.alertModalOpen}
                    title={this.state.alertModalTitle}
                    message={this.state.alertModalMessage}>
                    {
                        this.state.alertModalButtons.map((button) => (
                            <ModalButton label={button.label} onClick={button.onClick}
                                         className={button.className}/>
                        ))
                    }
                </SimpleAlertDialog>
            </div>);

        }

    }


}

export default AutofillInvoiceQuote;
