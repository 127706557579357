
import React from 'react'
import SectionButton from "../common/SectionButton";


class OfficeMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
         }
    }

    render() {

        return(<div className="main-wrap">
            <div className="cell small-12 header">
                <h1 className="head-title office">Office</h1>
            </div>

            <div className="cell small-6">
                <SectionButton title="Personal / Business Details"
                               sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.AUTOFILL)}
                               iconClass="icon-prev-forms"/>
                <SectionButton title="View previous forms"
                               sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.PREV_FORMS)}
                               iconClass="icon-prev-forms"/>
                <SectionButton title="Create a new quote"
                               sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.CREATE_QUOTE)}
                               iconClass="icon-new-quote"/>
                <SectionButton title="Create new invoice"
                               sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.CREATE_INVOICE)}
                               iconClass="icon-new-invoice"/>
                <SectionButton title="Reminders"
                               sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.REMINDERS)}
                               iconClass="icon-reminders"/>
            </div>
            <div className="cell small-6">
                <SectionButton title="Manage &amp; Add Customers"
                               sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.CUSTOMERS)}
                               iconClass="icon-customers"/>
                <SectionButton title="View previous quotes"
                               sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.PREV_QUOTES)}
                               iconClass="icon-view-quote"/>
                <SectionButton title="View previous invoices"
                               sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.PREV_INVOICES)}
                               iconClass="icon-view-invoice"/>
                <SectionButton title="Calendar"
                               sectionClick={() => this.props.sectionClickHandler(this.props.SCREENS.CALENDAR)}
                               iconClass="icon-calendar"/>

            </div>
        </div>);

    }


}

export default OfficeMenu;
