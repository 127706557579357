import React from 'react'
import BackButton from "../common/BackButton"
import { API } from '../../api/APIClient'
import Axios from 'axios'

class ChangeEmail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password: "",
            email: "",
            email2: "",
            submitting:false,
            submitMessage:""
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });


    }

    submitChangeRequest = () => {

        if (this.state.email.length > 0 && this.state.email2.length > 0 && this.state.password.length > 0 )
        {
            if (this.state.email === this.state.email2)
            {

                this.setState({
                    submitting:true,
                    submitMessage:"Sending request..."
                });

                const formData = new FormData();
                formData.append('newEmail', this.state.email);
                formData.append('existingPassword', this.state.password);

                Axios
                    .post(API.auth.changeEmail, formData)
                    .then((res) => {

                        this.setState({
                            submitting:false,
                            submitMessage:""
                        });
                        if (res.data.success) {

                            alert("Successfully updated email")
                            this.props.backHandler()
                        }

                    }).catch(error => {

                        this.setState({
                            submitting:false,
                            submitMessage:""
                        });

                        console.log(error.response)
                        if (error.response.data.error) {

                            alert(error.response.data.error)
                        } else{
                            alert("Could not change email.  Please check connection and try again.")

                        }
                    });

            } else {
                alert("Email and Confirm Email fields do not match. Please try again.")

            }

        } else {

            alert( "Please enter your current password, new password and confirm the new password.")
        }


    }


    componentDidMount() {

    }


    render() {

        return (
            <div className="main-wrap">
                <div className="cell small-12 header">
                    <BackButton backHandler={this.props.backHandler} />
                    <h1 className="head-title toolbox">My Account  > Change Email Address</h1>
                </div>
                <div className="create-box">
                    <div className="cell small-12">

                        <h2>Change Your Email Address</h2>
                    
                        <div className="cell small-12">
                            <label>
                                <span className="create-label">New Email:</span>
                                <input className="create-title" type="text" name="email" placeholder="Email" value={this.state.email} onChange={this.handleChange}/>
                            </label>
                        </div>
                        <div className="cell small-12">
                            <label>
                                <span className="create-label">Confirm New Email:</span>
                                <input className="create-title" type="text" name="email2" placeholder="Email" value={this.state.email2} onChange={this.handleChange}/>
                            </label>
                        </div>
                        <div className="cell small-12">
                            <label>
                                <span className="create-label">Enter Current Password:</span>
                                <input className="create-title" type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.handleChange}/>
                            </label>
                        </div>

                        <div className="cell small-12">
                            {this.state.submitting ? <div>{this.state.submitMessage}</div> :
                            <div onClick={() => this.submitChangeRequest()} className="ga-button">Change Email</div>
                            }
                        </div>
                    
                </div>
                </div>
            </div>
        );
    }
}

export default ChangeEmail;
