import React from 'react'
import BackButton from "../common/BackButton"
import { API } from '../../api/APIClient'
import Axios from 'axios'
import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";


class Team extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            team: undefined,
            loading : true,
            alertModalOpen : false,
            alertModalTitle : null,
            alertModalMessage: null,
            alertModalButtons : [],
            invoiceFlag : false,
            showInvite : false,
            userAdded : undefined,
            userAwaiting : undefined,
            userMember: undefined
        };

        this.handleChange = this.handleChange.bind(this);

    }



    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === "invoiceFlag") {
             this.setState({
                [name]: target.checked
            }, () => this.updateInvoiceFlag());

        } else {
            this.setState({
                [name]: value
            });

        }

    }


    showInvite() {
        this.setState({
            showInvite : true,
            inviteEmail : ""
        });
    }


    hideInvite() {
        this.setState({
            showInvite : false,
            userAdded : undefined
        });
    }


    removeFromOrg() {

        var userID = this.state.userAwaiting.userID
        this.hideAwaiting()

        this.showAlertModal(
            "Remove user?",
            "Are you sure you want to remove this user?",
            [
                {
                    label : "Remove now",
                    onClick : () => {
                        this.setState({
                            loading: true
                        }, this.callRemoveUser(userID))

                        this.hideAlertModal()
                    },
                    className : "success"
                },
                {
                    label : "Cancel",
                    className : "cancel",
                    onClick : () => {
                        this.hideAlertModal()
                    },
                }
            ]
        )
    }


    removeMemberFromOrg() {

        var userID = this.state.userMember.userID
        this.hideMember()

        this.showAlertModal(
            "Remove user?",
            "Are you sure you want to remove this user?",
            [
                {
                    label : "Remove now",
                    onClick : () => {
                        this.setState({
                            loading: true
                        }, this.callRemoveUser(userID))

                        this.hideAlertModal()
                    },
                    className : "success"
                },
                {
                    label : "Cancel",
                    className : "cancel",
                    onClick : () => {
                        this.hideAlertModal()
                    },
                }
            ]
        )
    }

    callRemoveUser(userID) {
        const formData = new FormData();
        formData.append('userID',userID);

        Axios
            .post(API.account.removeUser, formData)
            .then((res) => {

                if (res.data.success && res.data.data != null) {
                    this.getTeamDetails()

                } else {

                    var error = "unknow error"
                    if (res.data.error) {
                        error = res.data.error
                    }
                    this.showAlertModal(
                        "Error",
                        error,
                        [
                            {
                                label : "OK",
                                className : "cancel",
                                onClick : () => {
                                    this.hideAlertModal()
                                },
                            }
                        ]
                    )

                }


            })
    }

    resendInvite() {
        const formData = new FormData();
        formData.append('teamID',this.state.team.id);
        formData.append('email',this.state.userAwaiting.email);

        Axios
            .post(API.account.inviteMember, formData)
            .then((res) => {

                if (res.data.success && res.data.data != null) {
                    this.getTeamDetails()
                    this.hideAwaiting()
                    this.setState({
                        userAdded : res.data.data.userAdded
                    });
                } else {

                    var error = "unknow error"
                    if (res.data.error) {
                        error = res.data.error
                    }
                    this.showAlertModal(
                        "Error",
                        error,
                        [
                            {
                                label : "OK",
                                className : "cancel",
                                onClick : () => {
                                    this.hideAlertModal()
                                },
                            }
                        ]
                    )

                }


            })
    }



    hideAwaiting() {
        this.setState({
            userAwaiting : undefined
        });
    }
    hideMember() {
        this.setState({
            userMember : undefined
        });
    }


    sendInvite() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!this.state.inviteEmail || !emailRegex.test(this.state.inviteEmail)) {
            this.showAlertModal(
                "Please enter a valid email address",
                "",
                [
                    {
                        label : "OK",
                        className : "cancel",
                        onClick : () => {
                            this.hideAlertModal()
                        },
                    }
                ]
            )

            return;
        }


        const formData = new FormData();
        formData.append('teamID',this.state.team.id);
        formData.append('email',this.state.inviteEmail);

        Axios
            .post(API.account.inviteMember, formData)
            .then((res) => {

                if (res.data.success && res.data.data != null) {
                    this.hideInvite()
                    this.getTeamDetails()
                    this.setState({
                        userAdded : res.data.data.userAdded
                    });
                } else {

                    var error = "unknow error"
                    if (res.data.error) {
                        error = res.data.error
                    }
                    this.showAlertModal(
                        "Error",
                        error,
                        [
                            {
                                label : "OK",
                                className : "cancel",
                                onClick : () => {
                                    this.hideAlertModal()
                                },
                            }
                        ]
                    )

                }


            })
    }

    componentDidMount() {
        this.getTeamDetails();
    }


    hideAlertModal() {
        this.setState({
            alertModalOpen : false
        });
    }

    showAlertModal(title, message, buttons) {
        this.setState({
            alertModalOpen : true,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        });
    }


    getTeamDetails = () => {
        Axios
            .get(API.account.getTeamData)
            .then((res) => {

                if (res.data.success && res.data.data != null) {

                    var flag = res.data.data.team.showInvoices === 1 ? true : false
                    this.setState({
                        team : res.data.data.team,
                        invoiceFlag: flag,
                        loading : false
                    })

                    if(this.state.team != null) {
                        this.getEngineers()
                    }
                } else {
                    this.setState({
                        loading: false,
                        team : undefined

                    })
                }


            })
    }

    updateInvoiceFlag = () => {


        const formData = new FormData();
        formData.append('showInvoices',this.state.invoiceFlag ? 1 : 0);

        Axios
            .post(API.account.updateInvoiceFlag, formData)
            .then((res) => {

                this.setState({
                    loading: false
                })

            }).catch(error => {

            this.setState({
                loading: false
            })
            console.log(error.response)

        });
    }


    getEngineers() {

    }


    createOrg() {
        this.showAlertModal(
            "Create an organisation?",
            "This will create an organisation with you as the leader / organiser and you will be able to invite other Gas App users to you team.",
            [
                {
                    label : "Yes, create organisation",
                    onClick : () => {
                        this.setState({
                            loading: true
                        }, this.callCreateTeam())

                        this.hideAlertModal()
                    },
                    className : "success"
                },
                {
                    label : "Cancel",
                    className : "cancel",
                    onClick : () => {
                        this.hideAlertModal()
                    },
                }
            ]
        )
    }


    leaveTeam() {
        this.showAlertModal(
            "Leave organisation?",
            "Are you sure? You will lose access to any forms, customers, quotes or invoices created under this organisation.",
            [
                {
                    label : "Leave now",
                    onClick : () => {
                        this.setState({
                            loading: true
                        }, this.callLeaveTeam())

                        this.hideAlertModal()
                    },
                    className : "success"
                },
                {
                    label : "Cancel",
                    className : "cancel",
                    onClick : () => {
                        this.hideAlertModal()
                    },
                }
            ]
        )
    }



    disbandOrg() {
        this.showAlertModal(
            "Disband team?",
            "Are you sure? All members will be removed, but any forms, customers, quotes or invoices created will still be available to you.",
            [
                {
                    label : "Disband now",
                    onClick : () => {
                        this.setState({
                            loading: true
                        }, this.callDisbandTeam())

                        this.hideAlertModal()
                    },
                    className : "success"
                },
                {
                    label : "Cancel",
                    className : "cancel",
                    onClick : () => {
                        this.hideAlertModal()
                    },
                }
            ]
        )
    }

    callCreateTeam(){

        Axios
            .get(API.account.createTeam)
            .then((res) => {

                if (res.data.success && res.data.data != null) {

                    this.getTeamDetails()
                } else {
                    this.setState({
                        loading: false
                    })
                    var error = "unknow error"
                    if (res.data.error) {
                        error = res.data.error
                    }
                    this.showAlertModal(
                        "Error",
                        error,
                        [
                            {
                                label : "OK",
                                className : "cancel",
                                onClick : () => {
                                    this.hideAlertModal()
                                },
                            }
                        ]
                    )
                }


            })
    }

    callDisbandTeam(){

        Axios
            .get(API.account.disbandTeam)
            .then((res) => {

                if (res.data.success && res.data.data != null) {

                    this.getTeamDetails()
                } else {
                    this.setState({
                        loading: false
                    })
                }


            })
    }



    callLeaveTeam(){

        Axios
            .get(API.account.leaveTeam)
            .then((res) => {

                if (res.data.success && res.data.data != null) {

                    this.getTeamDetails()
                } else {
                    this.setState({
                        loading: false
                    })
                }


            })
    }


    showAwaiting(member)
    {
        this.setState({userAwaiting: member})
    }
    showMember(member)
    {
        this.setState({userMember: member})
    }


    render() {

        return (<div className="main-wrap">
            <div className="cell small-12"><BackButton backHandler={this.props.backHandler}/></div>
            <div className="cell small-12 no-pad">

                <div className="team">

                    <div className="cell small-12">
                        <div className="header">
                            <h1 className="head-title office">Manage Team Settings</h1>
                        </div>
                    </div>

                    <div className="content ">

            {(this.state.true) ? <>Loading team details</> :


                (this.state.team) ? (

                    (this.state.team.isOwner === 1) ? (

                        <>

                            <div className="cell small-8 flex-container">

                                <div className="cell small-12">
                                    <label className="bold-text autoemails">
                                        Show invoices to team members
                                        <input type="checkbox" name="invoiceFlag" checked={this.state.invoiceFlag} onChange={this.handleChange} />
                                        <span className="checkmark"></span>
                                    </label>

                                </div>
                                <div className="cell small-12 text-center">
                                    <div className="ga-button"style={{marginRight: "0px"}} onClick={() => this.showInvite()}>Add a user to this organisation</div>
                                </div>
                            </div>

                            <div className="cell small-4 " style={{ flex: 1 }}>
                                <div className="sect-header">
                                    <div className="title">Notice</div>
                                    <div>A maximum of {this.state.team.maxMembers} users can be added. To add more users simply email <a href="mailto:support@gasapp.co.uk">support@gasapp.co.uk</a></div>
                                </div>
                            </div>




                            <div className="cell small-12">
                                <div className="cell small-4">

                                    <div className="sect-header">
                                        <div className="title">Organisation members</div>
                                    </div>

                                    {this.state.team.members && this.state.team.members.length > 0 ? (
                                        <div className="cell small-12">
                                            {this.state.team.members.map((member) => (
                                                <div className="cell small-12 no-pad" style={{ display: "flex", alignItems: "center" }} onClick={() => this.showMember(member)}>
                                                    <div className="cell small-3 no-pad ">
                                                        <img src={member.profilePictureUri} width="69" alt="Profile Thumb" />
                                                    </div>
                                                    <div className="cell small-9 " style={{ display: "flex", alignItems: "center" }}>
                                                        {member.name}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>                                    ) : (
                                        <div className="cell-12 text-center" style={{marginTop:"12px"}}>This team has no members</div>
                                    )}



                                </div>
                                    <div className="cell small-4">
                                        <div className="sect-header">
                                            <div className="title">Awaiting approval</div>
                                        </div>


                                        {this.state.team.membersInvited && this.state.team.membersInvited.length > 0 ? (
                                            <div className="cell small-12">
                                                {this.state.team.membersInvited.map((member) => (
                                                     <div className="cell small-12 no-pad" style={{ display: "flex", alignItems: "center" }} onClick={() => this.showAwaiting(member)}>
                                                        <div className="cell small-3 no-pad ">
                                                            <img src={member.profilePictureUri} width="69" alt="Profile Thumb" />
                                                        </div>
                                                        <div className="cell small-9 " style={{ display: "flex", alignItems: "center" }}>
                                                            {member.name}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="cell-12 text-center" style={{marginTop:"12px"}}>There are no pending approvals</div>
                                        )}




                                    </div>

                                <div className="cell small-4">
                                    <div className="sect-header">
                                        <div className="title">Declined invites</div>
                                    </div>

                                    {this.state.team.membersDeclined && this.state.team.membersDeclined.length > 0 ? (
                                        <div className="cell small-12">
                                            {this.state.team.membersDeclined.map((member) => (
                                                <div className="cell small-12 no-pad" style={{ display: "flex", alignItems: "center" }} onClick={() => this.showMember(member)}>
                                                    <div className="cell small-3 no-pad ">
                                                        <img src={member.profilePictureUri} width="69" alt="Profile Thumb" />
                                                    </div>
                                                    <div className="cell small-9 " style={{ display: "flex", alignItems: "center" }}>
                                                        {member.name}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="cell-12 text-center" style={{marginTop:"12px"}}>No invites have been declined</div>
                                    )}



                                </div>
                            </div>

                            <div className="cell small-12 text-center">

                                <div className="ga-button delete" onClick={() => this.disbandOrg()}>Disband Team</div>
                            </div>


                        </>
                        ) : (
                        (this.state.team.staus === "invited") ? (
                            <>
                                <div>Please open the app to accept team invites.</div>

                            </>

                        ) : (
                            <>
                                <div className="cell small-12 text-center">
                                    You are part of {this.state.team.ownerName} organisation.
                                </div>
                                <div className="cell small-4">
                                </div>
                                <div className="cell small-4">
                                    <div className="sect-header">
                                        <div className="title">Organisation members</div>
                                    </div>

                                    {this.state.team.members && this.state.team.members.length > 0 ? (
                                        <div className="cell small-12">
                                            {this.state.team.members.map((member) => (
                                                <div className="cell small-12 no-pad" style={{ display: "flex", alignItems: "center" }}  >
                                                    <div className="cell small-3 no-pad ">
                                                        <img src={member.profilePictureUri} width="69" alt="Profile Thumb" />
                                                    </div>
                                                    <div className="cell small-9 " style={{ display: "flex", alignItems: "center" }}>
                                                        {member.name}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="cell-12 text-center" style={{marginTop:"12px"}}>No members found</div>
                                    )}



                                </div>
                                <div className="cell small-4">
                                </div>
                                <div className="cell small-12 text-center">

                                    <div className="ga-button delete" onClick={() => this.leaveTeam()}>Leave this organisation</div>
                                </div>
                            </>

                        )

                    )

                ) : (

                    <div className=" text-center no-team">
                        <div className="ga-button" onClick={() => this.createOrg()}>Create an organisation</div>
                    </div>
                )

            }


                    </div>
                </div>
            </div>

            {this.state.userAdded && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Invite Sent</h2>


                        <div className="cell small-12 no-pad">An organisation invite has been sent to </div>

                        <div className="cell small-12 no-pad">&nbsp;</div>


                        <div className="cell small-12 no-pad" style={{ display: "flex", alignItems: "center" }}>
                            <div className="cell small-3 no-pad ">
                                <img src={this.state.userAdded.profilePictureUri} width="69" alt="Profile Thumb" />
                            </div>
                            <div className="cell small-9 " style={{ display: "flex", alignItems: "center" }}>
                                {this.state.userAdded.givenName} {this.state.userAdded.familyName}
                            </div>
                        </div>

                        <div className="cell small-12 no-pad">&nbsp;</div>

                        <div className="cell small-12 centered no-pad"><div onClick={() => this.hideInvite()} className="ga-button" style={{marginRight:"0px",width: "200px"}}>OK</div></div>
                        &nbsp;


                    </div>
                </div>
            )}

            {this.state.userAwaiting && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Awaiting approval</h2>


                        <div className="cell small-12 no-pad">&nbsp;</div>


                        <div className="cell small-12 no-pad" style={{ display: "flex", alignItems: "center" }}>
                            <div className="cell small-3 no-pad ">
                                <img src={this.state.userAwaiting.profilePictureUri} width="69" alt="Profile Thumb" />
                            </div>
                            <div className="cell small-9 " style={{ display: "flex", alignItems: "center" }}>
                                {this.state.userAwaiting.name}
                            </div>
                        </div>

                        <div className="cell small-12 no-pad">&nbsp;</div>
                        <div className="cell small-12 centered  "><div onClick={() => this.resendInvite()} className="ga-button"  style={{marginRight:"0px",width: "222px"}}>Resend Invite</div></div>
                        <div className="cell small-12 centered no-pad"><div onClick={() => this.removeFromOrg()} className="ga-button"  style={{marginRight:"0px",width: "222px"}}>Remove user </div></div>

                        <div className="cell small-12 centered  "><div onClick={() => this.hideAwaiting()} className="ga-button" style={{marginRight:"0px",width: "222px",background:"#999"}}>Cancel</div></div>
                        &nbsp;


                    </div>
                </div>
            )}

            {this.state.userMember && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>User</h2>


                        <div className="cell small-12 no-pad">&nbsp;</div>


                        <div className="cell small-12 no-pad" style={{ display: "flex", alignItems: "center" }}>
                            <div className="cell small-3 no-pad ">
                                <img src={this.state.userMember.profilePictureUri} width="69" alt="Profile Thumb" />
                            </div>
                            <div className="cell small-9 " style={{ display: "flex", alignItems: "center" }}>
                                {this.state.userMember.name}
                            </div>
                        </div>

                        <div className="cell small-12 no-pad">&nbsp;</div>
                        <div className="cell small-12 centered no-pad"><div onClick={() => this.removeMemberFromOrg()} className="ga-button"  style={{marginRight:"0px",width: "222px"}}>Remove user </div></div>

                        <div className="cell small-12 centered  "><div onClick={() => this.hideMember()} className="ga-button" style={{marginRight:"0px",width: "222px",background:"#999"}}>Cancel</div></div>
                        &nbsp;


                    </div>
                </div>
            )}


            {this.state.showInvite && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Add the user's Email</h2>


                        <div className="cell small-12 no-pad">Add the user's email to send them an invite to join your organisation</div>

                        <div className="cell small-12 no-pad">&nbsp;</div>
                        <label>
                            <input className="edit-field" type="text" name="inviteEmail" value={this.state.inviteEmail}
                                   onChange={this.handleChange}/>
                        </label>


                        <div className="cell small-12 centered"><div onClick={() => this.sendInvite()} className="ga-button"  style={{marginRight:"0px",width: "200px"}}>Send Invite</div></div>
                        <div className="cell small-12 centered no-pad"><div onClick={() => this.hideInvite()} className="ga-button" style={{marginRight:"0px",width: "200px",background:"#999"}}>Cancel</div></div>
                        &nbsp;


                    </div>
                </div>
            )}
            <SimpleAlertDialog
                open={this.state.alertModalOpen}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}>
                {
                    this.state.alertModalButtons.map((button) => (
                        <ModalButton label={button.label} onClick={button.onClick}
                                     className={button.className}/>
                    ))
                }
            </SimpleAlertDialog>

        </div>);
    }

 }

export default Team;
