
import React, { createRef } from "react";
import BackButton from "../common/BackButton";
import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";
import Axios from "axios";
import {API} from "../../api/APIClient";
import qs from 'qs';
import SignatureCanvas from "react-signature-canvas";


class AutofillDetails extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            loading: true,
            alertModalOpen : false,
            alertModalTitle : null,
            alertModalMessage: null,
            alertModalButtons : []
        }
        this.sigCanvas = createRef(); // Create ref for SignatureCanvas

        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;


            this.setState({
                [name]: value
            });

    }


    componentDidMount() {

        this.getDetails();
    }

    clearSig() {
        this.showAlertModal(
            "Clear Signature?",
            "Do you want to reset the signature?",
            [
                {
                    label : "Yes, reset",
                    onClick : () => {
                        this.setState({
                            business_details_signature : undefined
                        })

                        this.hideAlertModal()
                    },
                    className : "success"
                },
                {
                    label : "Cancel",
                    className : "cancel",
                    onClick : () => {
                        this.hideAlertModal()
                    },
                }
            ]
        )
    }

    getDetails = () => {
        Axios.get(API.user.get_autofill_details)
            .then((res) => {
                if (res.data.success) {
                    // Extract address_data & engineer_data
                    const { address_data, engineer_data } = res.data;

                    // Convert array to object using key-value pairs
                    const addressObj = address_data.reduce((acc, item) => {
                        acc[item.key] = item.value;
                        return acc;
                    }, {});

                    const engineerObj = engineer_data.reduce((acc, item) => {
                        acc[item.key] = item.value;
                        return acc;
                    }, {});

                    // Set state dynamically
                    this.setState({
                        ...addressObj,
                        ...engineerObj,
                        loading: false,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };



    hideAlertModal() {
        this.setState({
            alertModalOpen : false
        });
    }

    showAlertModal(title, message, buttons) {
        this.setState({
            alertModalOpen : true,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        });
    }



    prepareToSave = () => {

        //sig updated???
        if (this.state.business_details_signature === undefined){
            if (!this.sigCanvas.current.isEmpty()) {
                const base64Signature = this.sigCanvas.current.toDataURL("image/png").split(",")[1]; // Remove header
                this.setState({
                    business_details_signature : base64Signature
                }, () => this.saveChanges());
            } else {
                this.saveChanges()

            }
        } else {
            this.saveChanges()
        }
    }

    saveChanges() {


        // Keys for each category
        const addressKeys = [
            "business_details_address1",
            "business_details_address2",
            "business_details_city",
            "business_details_county",
            "business_details_postcode"
        ];

        const engineerKeys = [
            "business_details_person_name",
            "business_details_name",
            "formId_gasRegSafeNo",
            "signoff_operative_id",
            "company_no",
            "vat_reg_no",
            "business_details_telephone",
            "oftec_license",
            "technician_license",
            "business_details_signature"
        ];

        // Convert flat state into structured objects for API
        const formatData = (keys, category) =>
            keys
                .filter((key) => this.state[key] !== undefined) // Only include defined values
                .map((key) => ({
                    key: key,
                    title: key.replace(/_/g, " "), // Convert snake_case to readable title
                    value: this.state[key],
                }));

        const addressData = formatData(addressKeys, "AUTOFILL_ADDRESS");
        const engineerData = formatData(engineerKeys, "AUTOFILL_ENGINEER");

        // Format for URL-encoded submission
        const requestBody = qs.stringify({
            AUTOFILL_ADDRESS: addressData,
            AUTOFILL_ENGINEER: engineerData,
        }, { encode: true, arrayFormat: "brackets" });


        // Send POST request
        Axios.post(API.user.set_autofill_details, requestBody)
            .then((res) => {
                this.showAlertModal(
                    "Details Saved!",
                    "Your details have been saved.",
                    [{ label: "OK", onClick: () => this.hideAlertModal(), className: "success" }]
                );
            })
            .catch(error => {
                console.log(error.response);
                alert(error.response?.data?.error || "Could not submit data. Please check connection and try again.");
            });
    };



    render() {

        if (this.state.loading){
            return(<div className="main-wrap">
                <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>

                <div className="cell small-12">
                    <div className="">Loading...</div>
                </div>
            </div>);

        } else {
            return(<div className="main-wrap">
                <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>
                <div className="autofill">


                    <div className="cell small-12">

                        <div className="header">
                            <h1 className="head-title office">Your Details &amp; Business Details</h1>
                        </div>
                    </div>
                    <div className="cell small-12">
                        The details you provide below will be used to automatically fill relevant fields in forms and quotes throughout the app
                    </div>

                    <div className="cell small-6">
                        <div className="grey-head bold-text" style={{marginBottom:"20px"}}>Your Details</div>
                        <div className="cell small-12 no-pad non-bold-text" >Your Name</div>
                        <label>
                            <input className="edit-field" type="text" name="business_details_person_name"  value={this.state.business_details_person_name} onChange={this.handleChange}/>
                        </label>
                        <div className="cell small-12  "> </div>

                        <div className="cell small-12 no-pad non-bold-text" >Business Name</div>
                        <label>
                            <input className="edit-field" type="text" name="business_details_name"  value={this.state.business_details_name} onChange={this.handleChange}/>
                        </label>

                        <div className="cell small-12  "> </div>

                        <div className="cell small-12 no-pad non-bold-text" >Gas Safe Number</div>
                        <label>
                            <input className="edit-field" type="text" name="formId_gasRegSafeNo"  value={this.state.formId_gasRegSafeNo} onChange={this.handleChange}/>
                        </label>
                        <div className="cell small-12  "> </div>

                        <div className="cell small-12 no-pad non-bold-text" >License Number</div>
                        <label>
                            <input className="edit-field" type="text" name="signoff_operative_id"  value={this.state.signoff_operative_id} onChange={this.handleChange}/>
                        </label>
                        <div className="cell small-12  "> </div>

                        <div className="cell small-12 no-pad non-bold-text" >Company Number</div>
                        <label>
                            <input className="edit-field" type="text" name="company_no"  value={this.state.company_no} onChange={this.handleChange}/>
                        </label>
                        <div className="cell small-12  "> </div>


                        <div className="cell small-12 no-pad non-bold-text" >VAT No.</div>
                        <label>
                            <input className="edit-field" type="text" name="vat_reg_no"  value={this.state.vat_reg_no} onChange={this.handleChange}/>
                        </label>
                        <div className="cell small-12  "> </div>

                        <div className="cell small-12 no-pad non-bold-text" >Contact Number</div>
                        <label>
                            <input className="edit-field" type="text" name="business_details_telephone"  value={this.state.business_details_telephone} onChange={this.handleChange}/>
                        </label>
                        <div className="cell small-12  "> </div>

                        <div className="cell small-12 no-pad non-bold-text" >OFTEC Company Number</div>
                        <label>
                            <input className="edit-field" type="text" name="oftec_license"  value={this.state.oftec_license} onChange={this.handleChange}/>
                        </label>
                        <div className="cell small-12  "> </div>

                        <div className="cell small-12 no-pad non-bold-text" >OFTEC Technician License</div>
                        <label>
                            <input className="edit-field" type="text" name="technician_license"  value={this.state.technician_license} onChange={this.handleChange}/>
                        </label>
                    </div>
                    <div className="cell small-6">
                        <div className="grey-head bold-text" style={{marginBottom:"20px"}}>Business Address</div>

                        <div className="cell small-12 no-pad non-bold-text" >Address Line 1</div>
                        <label>
                            <input className="edit-field" type="text" name="business_details_address1"  value={this.state.business_details_address1} onChange={this.handleChange}/>
                        </label>
                        <div className="cell small-12  "> </div>

                        <div className="cell small-12 no-pad non-bold-text" >Address Line 2</div>
                        <label>
                            <input className="edit-field" type="text" name="business_details_address2"  value={this.state.business_details_address2} onChange={this.handleChange}/>
                        </label>

                        <div className="cell small-12  "> </div>

                        <div className="cell small-12 no-pad non-bold-text" >Town/City</div>
                        <label>
                            <input className="edit-field" type="text" name="business_details_city"  value={this.state.business_details_city} onChange={this.handleChange}/>
                        </label>
                        <div className="cell small-12  "> </div>

                        <div className="cell small-12 no-pad non-bold-text" >County</div>
                        <label>
                            <input className="edit-field" type="text" name="business_details_county"  value={this.state.business_details_county} onChange={this.handleChange}/>
                        </label>
                        <div className="cell small-12  "> </div>

                        <div className="cell small-12 no-pad non-bold-text" >Postcode</div>
                        <label>
                            <input className="edit-field" type="text" name="business_details_postcode"  value={this.state.business_details_postcode} onChange={this.handleChange}/>
                        </label>

                        <div className="grey-head bold-text" style={{marginBottom:"20px", marginTop:"20px"}}>Signature</div>

                        {this.state.business_details_signature ? (
                            <img
                                onClick={() => this.clearSig()}
                                src={`data:image/png;base64,${this.state.business_details_signature}`}
                                alt="Signature"
                                style={{ width: "100%", border: "1px solid #ccc" }}
                            />
                        ) : (
                            // Show Signature Pad when no signature is available
                            <SignatureCanvas
                                ref={this.sigCanvas}
                                penColor="black"
                                canvasProps={{ width: 450, height: 200, className: "signatureCanvas",
                                    style: { border: "1px solid #ccc", borderRadius: "5px" }}}
                            />
                        )}
                    </div>

                </div>
                <div className="cell small-12  " >
                </div>
                <div className="cell small-4 no-pad" >&nbsp;
                </div>
                <div className="cell small-4 no-pad" >
                    <div className="ga-button" style={{width: "100%", marginRight:"0px"}} onClick={() => this.prepareToSave()}>Save</div>
                </div>
                <div className="cell small-4 no-pad" >&nbsp;
                </div>
                <div className="cell small-12  " >
                </div>

                <SimpleAlertDialog
                    open={this.state.alertModalOpen}
                    title={this.state.alertModalTitle}
                    message={this.state.alertModalMessage}>
                    {
                        this.state.alertModalButtons.map((button) => (
                            <ModalButton label={button.label} onClick={button.onClick}
                                         className={button.className}/>
                        ))
                    }
                </SimpleAlertDialog>
            </div>);
        }


    }


}

export default AutofillDetails;
