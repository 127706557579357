
import React from 'react'
import BackButton from "../common/BackButton";
import Axios from "axios";
import {API} from "../../api/APIClient";
import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";

class AutofillPayment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            alertModalOpen : false,
            alertModalTitle : null,
            alertModalMessage: null,
            alertModalButtons : [],
            bankName: "",
            accountName:  "",
            accountNumber:  "",
            sort1:  "",
            sort2:  "",
            sort3: "",
            paymentTerms:  ""
        }

        this.handleChange = this.handleChange.bind(this);
    }





    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;


        this.setState({
            [name]: value
        });

    }


    componentDidMount() {

        this.getPaymentDetails();
    }

    getPaymentDetails = () => {
        Axios.get(API.autoFill.getPaymentDetails)
            .then((res) => {
                if (res.data.success) {
                    const sortParts = res.data.data.sortCode.split("-");

                    this.setState({
                        bankName: res.data.data.bankName,
                        accountName: res.data.data.accountName,
                        accountNumber: res.data.data.accountNumber,
                        sort1: sortParts[0] || "",
                        sort2: sortParts[1] || "",
                        sort3: sortParts[2] || "",
                        paymentTerms: res.data.data.paymentTerms,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };


    hideAlertModal() {
        this.setState({
            alertModalOpen : false
        });
    }

    showAlertModal(title, message, buttons) {
        this.setState({
            alertModalOpen : true,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        });
    }


    saveChanges() {

        const formData = new FormData();
        formData.append('accountName', this.state.accountName);
        formData.append('accountNumber', this.state.accountNumber);
        formData.append('bankName', this.state.bankName);
        formData.append('paymentTerms', this.state.paymentTerms);
        formData.append('sortCode', this.state.sort1+"-"+this.state.sort2+"-"+this.state.sort3);
        Axios
            .post(API.autoFill.updatePaymentDetails, formData)
            .then((res) => {

                this.showAlertModal(
                    "Details Saved!",
                    "Payment details have been saved.",
                    [
                        {
                            label : "OK ",
                            onClick : () => {
                                this.hideAlertModal() },
                            className : "success"
                        }

                    ]
                )


            }).catch(error => {

            console.log(error.response)
            if (error.response.data.error) {

                alert(error.response.data.error)
            } else {
                alert("Could not submit terms.  Please check connection and try again.")
            }
        });


    }




    render() {

        if (this.state.loading){
            return(<div className="main-wrap">
                <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>

                <div className="cell small-12">
                    <div className="">Loading...</div>
                </div>
            </div>);

        } else {
            return(<div className="main-wrap">
                <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>

                <div className="autofill">
                    <div className="cell small-12">

                        <div className="header">
                            <h1 className="head-title office">Payment Details</h1>
                        </div>
                    </div>

                    <div className="cell small-4">
                        <div className="cell small-12 no-pad bold-text input-title" >Bank Name</div>
                        <label>
                            <input className="edit-field" type="text" name="bankName"  value={this.state.bankName} onChange={this.handleChange}/>
                        </label>
                    </div>
                    <div className="cell small-4">
                        <div className="cell small-12 no-pad bold-text input-title" >Account Number</div>
                        <label>
                            <input className="edit-field" type="text" name="accountNumber"  value={this.state.accountNumber} onChange={this.handleChange}/>
                        </label>
                    </div>
                    <div className="cell small-4">
                        <div className="cell small-12 no-pad bold-text input-title" >Account Name</div>
                        <label>
                            <input className="edit-field" type="text" name="accountName"  value={this.state.accountName} onChange={this.handleChange}/>
                        </label>
                    </div>
                    <div className="cell small-4">
                        <div className="cell small-12 no-pad bold-text input-title" >Sort Code </div>
                        <label>
                            <div className="cell small-3 no-pad">
                                <input className="edit-field" type="text" name="sort1"  value={this.state.sort1} onChange={this.handleChange}/>
                            </div>
                            <div className="cell small-1 no-pad">&nbsp;</div>
                            <div className="cell small-3 no-pad">
                                <input className="edit-field" type="text" name="sort2"  value={this.state.sort2} onChange={this.handleChange}/>
                            </div>
                            <div className="cell small-1 no-pad">&nbsp;</div>
                            <div className="cell small-3 no-pad">
                                <input className="edit-field" type="text" name="sort3"  value={this.state.sort3} onChange={this.handleChange}/>
                            </div>
                            <div className="cell small-1 no-pad">&nbsp;</div>

                        </label>
                    </div>
                    <div className="cell small-8">
                    <div className="cell small-12 no-pad bold-text input-title" >Payment Terms (this will be shown on Invoices and Quotes)</div>
                        <label>
                                <input className="edit-field" type="text" name="paymentTerms"  style={{width: "69px"}} value={this.state.paymentTerms} onChange={this.handleChange}/>&nbsp;&nbsp;Days
                        </label>
                    </div>

                </div>
                <div className="cell small-12  " >
                </div>
                <div className="cell small-4 no-pad" >&nbsp;
                </div>
                <div className="cell small-4 no-pad" >
                    <div className="ga-button" style={{width: "100%", marginRight:"0px"}} onClick={() => this.saveChanges()}>Save</div>
                </div>
                <div className="cell small-4 no-pad" >&nbsp;
                </div>
                <SimpleAlertDialog
                    open={this.state.alertModalOpen}
                    title={this.state.alertModalTitle}
                    message={this.state.alertModalMessage}>
                    {
                        this.state.alertModalButtons.map((button) => (
                            <ModalButton label={button.label} onClick={button.onClick}
                                         className={button.className}/>
                        ))
                    }
                </SimpleAlertDialog>
            </div>);

        }

    }


}

export default AutofillPayment;
