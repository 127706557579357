import React from 'react'
import MenuItem from './MenuItem'
import {SECTIONS} from '../../resources/strings'
import { Link } from "react-router-dom";
import Swiper from 'react-id-swiper';

function Menu(props) {

    function iconClassForSection(section) {

        switch (section) {

            case SECTIONS.OFFICE:
                return "menu-icon-office";
            case SECTIONS.SETTINGS:
                return "menu-icon-settings";
            case SECTIONS.BREAK_TIME:
                return "menu-icon-breaktime";
            case SECTIONS.WEBSITES:
                return "menu-icon-websites";
            default:
                return "menu-icon-office";
        };

    }

    function nameForSection(section) {

        switch (section) {

            case SECTIONS.OFFICE:
                return "Office";
            case SECTIONS.BREAK_TIME:
                return "Break Time";
            case SECTIONS.WEBSITES:
                return "Website Manager";
            case SECTIONS.SETTINGS:
                return "My Account";
            default:
                return "...";
        };

    }


//Swiper
    const params = {
        slidesPerView: 'auto',
        grabCursor:true
        //, Do we need arrows now there is only 4 items!?
        //navigation: {
       // nextEl: '.swiper-button-next',
       // prevEl: '.swiper-button-prev'
      //}
    };

    return(
        <div className="nav-wrap">
            <div className="nav-box">

                {
                    (props.subscription !== null && props.subscription.premium === 1)  ?
                            <Swiper {...params}>
                                <Link to={`/` + SECTIONS.OFFICE}><MenuItem itemName={nameForSection(SECTIONS.OFFICE)} iconClass={iconClassForSection(SECTIONS.OFFICE)}/></Link>
                                <Link to={`/` + SECTIONS.BREAK_TIME}><MenuItem itemName={nameForSection(SECTIONS.BREAK_TIME)} iconClass={iconClassForSection(SECTIONS.BREAK_TIME)} /></Link>
                                <Link to={'/' + SECTIONS.WEBSITES}><MenuItem itemName={nameForSection(SECTIONS.WEBSITES)} iconClass={iconClassForSection(SECTIONS.WEBSITES)} /></Link>
                                <Link to={`/` + SECTIONS.SETTINGS}><MenuItem itemName={nameForSection(SECTIONS.SETTINGS)} iconClass={iconClassForSection(SECTIONS.SETTINGS)} /></Link>
                            </Swiper>
                            :
                            <Swiper {...params}>
                                <Link to={'/' + SECTIONS.WEBSITES}><MenuItem itemName={nameForSection(SECTIONS.WEBSITES)} iconClass={iconClassForSection(SECTIONS.WEBSITES)} /></Link>
                                <Link to={`/` + SECTIONS.SETTINGS}><MenuItem itemName={nameForSection(SECTIONS.SETTINGS)} iconClass={iconClassForSection(SECTIONS.SETTINGS)} /></Link>
                            </Swiper>


                }

            </div>
        </div>
    );
}

export default Menu;
